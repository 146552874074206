import React, { useEffect } from 'react';
import Intro from './automation/Intro'
import Services from './automation/Services'
import About from './automation/About'
import BPM from './automation/BPM'
import Preloader from './home/Preloader'
import ScrollUp from './home/ScrollUp'

function Automation() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <Preloader></Preloader>
      <ScrollUp></ScrollUp>
      {/* <MainHeader></MainHeader> */}
      <div style={{ overflow: 'hidden' }}>
        <Intro></Intro>
        <Services></Services>
      </div>
      <BPM></BPM>
      <About></About>
    </div>

  );
}

export default Automation;
