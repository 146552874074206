import React from 'react'

function Preloader() {
    return (
        <div id="preloader">
            <div id="status">
                <dotlottie-player
                    src={`/assets/lottie/crowdylogo.lottie`}
                    background="transparent" speed="1" style={{ height: 90, display: "flex", justifyContent: "center" }} autoplay></dotlottie-player>
                {/* <div className="status-mes" >
                </div> */}
            </div>
        </div>
    );
}

export default Preloader
