import React from 'react'
import EmojiText from './EmojiText';
import moment from "moment"
import intl from "react-intl-universal";

function IPhoneMockup(props) {
    const [hour, setHour] = React.useState(new Date());
    var messageRef = React.useRef();

    React.useEffect(() => {
        setInterval(() => {
            setHour(new Date())
        }, 2000)
    }, [])

    const urlify = (text) => {
        var urlRegex = /(\s{1}\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)*[-A-Z0-9]{3,}[.]{1}[-A-Z0-9]{2,}[/]*[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]*)/igm;
        // var urlRegex = /(\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)*[-A-Z0-9+&@#\/%?=~_|!:,.;]+\/[-A-Z0-9+&@#\/%=~_|]+)/ig;
        // var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => {
            let target =
                props.enableLinks ?
                    /\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)/ig.test(url) ?
                        url.replace(' ', '')
                        : `http://${url.replace(' ', '')}`
                    : "#";
            return `<a href="${target}" ${props.enableLinks ? '' : 'onclick="return false;"'} target="_blank" class="phone-mockup-link">${url}</a>`;
        });
        // or alternatively
        // return text.replace(urlRegex, `<a href="${props.enableLinks ? /\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)/ig.test("\\$1") ? "$1".replace('\s', '') : "http://$1" : "#"}" ${props.enableLinks ? "" : 'onclick="return false;"'} target="_blank">$1</a>`)
    }

    return (
        <div style={{ padding: 20 }}>
            <div style={{ width: props.size ? props.size === 'md' ? 230 : props.size === 'sm' ? 220 : 270 : 220, position: 'relative' }}>
                <img
                    src="/assets/img-mockup/iPhone 13 Pro blue.png"
                    style={{ width: '112%', height: '103%', position: 'absolute', marginLeft: '-6%', marginTop: '-4%', maxWidth: "unset", left: 0 }}
                    alt=''
                />
                <img src="/assets/img-mockup/AMSBlueGreenTextRigh.svg" style={{ width: '17%', position: 'absolute', top: '1%', left: '8%' }} alt=''/>
                <img src="/assets/img-mockup/statusbar-right.svg" style={{ width: '18%', position: 'absolute', top: '2.1%', right: '8%' }} alt=''/>
                <img src="/assets/img-mockup/locked.svg" style={{ width: '6%', marginLeft: '48%', position: 'absolute', top: '6%', left: 0 }} alt=''/>
                <div style={{ width: '100%', position: 'absolute', textAlign: 'center', top: '11%', color: 'white', height: 253 }}>
                    <p style={{ fontFamily: 'SFLight', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 48 : 62 : 48, fontWeight: 100, margin: 0, lineHeight: 1.1 }}>
                        {moment(hour).format('h:mm')}
                    </p>
                    <p style={{ fontFamily: 'SFRegular', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 11 : 14 : 11, fontWeight: 100, margin: 0 }}>
                        {moment(hour).format('dddd, D [de] MMMM')}
                    </p>
                </div>
                <div style={{ width: '100%', position: 'absolute', display: 'flex', top: '28%', justifyContent: 'center', maxHeight: '40%', overflow: 'hidden' }}>
                    <div style={{ width: '82%', height: 8, position: 'absolute', bottom: 0, backgroundColor: '#ffffff40', borderRadius: '0 0 10px 10px', backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' }}>
                    </div>
                    <div style={{ width: '88%', height: 8, position: 'absolute', bottom: 7, backgroundColor: '#ffffff70', borderRadius: '0 0 10px 10px', backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' }}>
                    </div>
                    <div style={{ width: '94%', height: 'calc(100% - 14px)', position: 'absolute', top: '1%', backgroundColor: '#ffffffa3', borderRadius: 10, backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' }}>
                    </div>
                    <div style={{ width: '37%', position: 'absolute', left: 12, top: 8, display: 'inline-table', textAlign: 'left' }}>
                        <img src="/assets/img-mockup/IMessage_logo.svg" style={{ width: '19%', position: 'absolute', top: '2%', left: '4%' }} alt=''/>
                        <p style={{ textTransform: 'uppercase', fontFamily: 'SFRegular', fontWeight: 'bold', paddingLeft: '28%', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 8 : 10 : 8, color: '#0000007d', paddingTop: '5%', margin: 0 }}>
                            {intl.get('global.msg')}
                        </p>
                    </div>
                    <div style={{ width: '63%', position: 'absolute', right: 20, top: 8, textAlign: 'right' }}>
                        <p style={{ fontFamily: 'SFRegular', fontWeight: 'bold', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 7 : 9 : 7, color: '#0000007d', paddingTop: '3%', margin: 0 }}>
                            {moment(props.date).fromNow()}
                        </p>
                    </div>
                    <div style={{ width: '100%', color: 'black', zIndex: 1, padding: '2% 7% 8%', display: 'grid', textAlign: 'left' }}>
                        <p style={{ fontFamily: 'SFRegular', fontWeight: 'bold', margin: 0, paddingTop: '11%', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 9 : 11 : 9 }} >
                            {props.shortCode}
                        </p>
                        <p ref={messageRef}
                            dangerouslySetInnerHTML={
                                { __html: EmojiText.parse(urlify(props.text), false, props.size ? props.size === 'md' || props.size === 'sm' ? 13 : 15 : 13, 'apple') }
                            }
                            style={{ position: 'relative', paddingTop: '1%', margin: 0, fontFamily: 'SFRegular', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 9 : 11 : 9, marginTop: 1, lineHeight: 1.35 }}>
                        </p>
                    </div>
                </div>

                <img src="/assets/img-mockup/crowdylogotextwhite.svg" style={{ width: '50%', marginLeft: '25%', position: 'absolute', bottom: '18%' }} alt=''/>
                <img src="/assets/img-mockup/flashlight.svg" style={{ width: '12%', position: 'absolute', bottom: '6%', left: '12%' }} alt=''/>
                <img src="/assets/img-mockup/camera.svg" style={{ width: '12%', position: 'absolute', bottom: '6%', right: '12%' }} alt=''/>
                <img src="/assets/img-mockup/home-indicator.svg" style={{ width: '100%', position: 'absolute', bottom: '1.2%' }} alt=''/>

                <div style={{ width: '100%', borderRadius: '6%', overflow: 'hidden' }}>
                    <img src="/assets/img-mockup/new background3.svg" style={{ width: '100%' }} alt=''/>
                </div>

            </div>
        </div >
    )
}

export default IPhoneMockup;