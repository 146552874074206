import React, { useEffect } from 'react'

function Services() {

    const serviceList = [
        {
            Title: "Incentivar las ventas",
            Icon: "undraw_successful_purchase.svg",
            Detail: "Llega a un mayor número de clientes y comunícate con ellos mediante una IA conversacional inteligente."
        },
        {
            Title: "Reducción de costos",
            Icon: "undraw_real-time_sync_o57k.svg",
            Detail: "Utilizada por call centers o sistemas automatizados."
        },
        {
            Title: "Disponiblidad",
            Icon: "undraw_dashboard.svg",
            Detail: "Permite que varios usuarios accedan a la cuenta de la empresa desde diferentes dispositivos."
        },
    ]

    useEffect(() => {
        window.$("#owl-services").owlCarousel({
            items: 3,
            navigation: true,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            autoPlay: 10000,
            singleItem: false
        });
    })

    const renderServiceItem = (item, i) => {
        return (
            <div key={Math.random()} className="owl-item">
                <div className="col-sm-12">
                    <div className="service">
                        <div className="icon">
                            <img src={`/assets/images/illustrations/${item.Icon}`} style={{ height: 200, marginBottom: 10 }} alt="" />
                        </div>
                        {item.Url ?
                            <a href={item.Url} rel="noopener noreferrer" target="_blank">
                                <h4>{item.Title}</h4>
                            </a>
                            :
                            <h4>{item.Title}</h4>
                        }
                        <div className="text">
                            <p >{item.Detail}</p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <section id="services" className="section alt-section">
            <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div id="owl-services" className="owl-carousel">
                            {serviceList.map(renderServiceItem)}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Services
