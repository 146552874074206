import React, { useEffect } from 'react'

function Services() {

    const serviceList = [
        {
            Title: "Modelador BPM Web",
            Icon: "undraw_process_e90d.svg",
            Detail: "Nuestra interfaz de modelado web con soporte drag and drop basada en BPMN, hace que diseñar procesos sea una tarea sencilla."
        },
        {
            Title: "infraestructura dedicada",
            Icon: "undraw_secure_server_s9u8.svg",
            Detail: "Nuestro producto BPM es disponibilizado de manera aislada en infraestructura dedicada, esto hace que el desempeño y la seguridad sean optimos."
        },
        {
            Title: "más que comunicación",
            Icon: "undraw_online_connection_6778.svg",
            Detail: "No solamente se trata del uso de canales de comunicación digital, es posible integrar su bus de servicios para hacer operaciones dentro de sus propias aplicaciones."
        },
        // {
        //     Title: "capacidad",
        //     Icon: "undraw_speed_test_wxl0.svg",
        //     Detail: "Nuestros servicios estan optimizados para procesar una gran cantidad de mensajes en el orden de milisegundos, esto hace que los mensajes sean enviados casi de manera inmediata.",
        //     Url: "https://docs.crowdymessaging.com"
        // },
        // {
        //     Title: "Portal de Documentación",
        //     Icon: "undraw_reviewed_docs_neeb.svg",
        //     Detail: "Todo lo que necesita saber para integrarse en nuestro portal de documentación, desde ejemplos hasta librerias para que el proceso sea sencillo y rápido.",
        //     Url: "https://docs.crowdymessaging.com"
        // },
        // {
        //     "Title": "AGILE",
        //     "Icon": "scrum.svg",
        //     "Detail": "Nuestros desarrollos e implementaciones adoptan metodologías Agiles de desarrollo de software, esto garantiza que cada despliegue agregue valor a su negocio en tiempos cortos, para esto contamos con personal certificado en diferentes roles"
        // }, 
        // {
        //     "Title": "ITIL",
        //     "Icon": "itil.svg",
        //     "Detail": "Consultoría para análisis e implementación de buenas prácticas a nivel de procesos basados en ITIL, estos pueden mejorar considerablemente el ciclo de vida y la percepción de sus clientes ante el servicio ofrecido por su compañía."
        // }, {
        //     "Title": ".Net platform",
        //     "Icon": "microsoftlogo.svg",
        //     "Detail": "Contamos con un equipo experto en tecnologías Microsoft, esto nos da la capacidad de crear soluciones mantenibles y escalables en tiempos óptimos; si requiere ayuda o consultoría podemos ayudarle tanto en desarrollo como en implementación"
        // },
        // {
        //     "Title": "Mirosoft Azure CI/CD",
        //     "Icon": "microsoftlogo.svg",
        //     "Detail": "Ofrecemos consultoria para procesos de Integración Continua y Despliegue Continuo basados en la nube de Microsoft Azure"
        // }
    ]

    useEffect(() => {
        window.$("#owl-services").owlCarousel({
            items: 3,
            navigation: true,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            autoPlay: 10000,
            singleItem: false
        });
    })

    const renderServiceItem = (item, i) => {
        return (
            <div key={Math.random()} className="owl-item">
                <div className="col-sm-12">
                    <div className="service">
                        <div className="icon">
                            <img src={`/assets/images/illustrations/${item.Icon}`} style={{ height: 200, marginBottom: 10 }} alt="" />
                        </div>
                        {item.Url ?
                            <a href={item.Url} rel="noopener noreferrer" target="_blank">
                                <h4>{item.Title}</h4>
                            </a>
                            :
                            <h4>{item.Title}</h4>
                        }
                        <div className="text">
                            <p >{item.Detail}</p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <section id="services" className="section alt-section rotate-section">
            <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div id="owl-services" className="owl-carousel">
                            {serviceList.map(renderServiceItem)}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Services
