import React from 'react'
import ReactTextRotator from 'react-text-rotator';
import Particles from 'react-particles-js';

function Intro() {

    return (
        <section id="intro" className="section" style={{background: 'linear-gradient(135deg, #261675 0%, #00abff 140%)'}}>
        {/* <section id="intro" className="section" >  */}
            <Particles style={{ position: 'absolute', left: 0, top: 0 }}
                params={{
                    "particles": {
                        "number": {
                            "value": 20,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 6,
                            "random": true,
                            "anim": {
                                "speed": 0.4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 0.4,
                            "direction": "top",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onclick": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        },
                        "modes": {
                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}
            ></Particles>

            <div className="container" id="particlejs">
                <div className="row">
                    <div className="col-md-8">
                        <div className="hello wow zoomIn faster">
                            <br></br>
                            <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px', textTransform: 'none', margin: '0 10%' }}>
                                Diseñe Flujos BPM haciendo uso de nuestros canales con un producto especializado integrando sus propios servicios.
                                </h3>
                            <br />
                            <h3>
                                <ReactTextRotator
                                    content={[
                                        {
                                            text: 'Automatización',
                                            animation: 'fade',
                                        },
                                        {
                                            text: 'Trazabilidad',
                                            animation: 'fade',
                                        },
                                        {
                                            text: 'Visibilidad',
                                            animation: 'fade',
                                        },
                                    ]}
                                    time={3000}
                                    startDelay={0}
                                />
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeIn crowdy-portal" style={{ animationDelay: '500ms' }}>
                        <img src={`/assets/images/illustrations/undraw_prototyping_process_rswj.svg`} style={{ maxWidth: '90%' }} alt="" />
                    </div>
                    <div className="col-md-12">
                        <a href="#about">
                            <div className="mouse-icon" style={{ marginTop: -50 }}>
                                <div className="wheel"></div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;