import React from 'react';

function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <h4 style={{ color: '#FFF', margin: 0 }}>{`Siguenos en`}</h4> */}
                            <ul id="social-links" className="icons-widget" style={{ marginLeft: 10 }}>
                                <li>
                                    <a href="https://linkedin.com/company/crowdy-messaging" rel="noopener noreferrer" target="_blank">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="/#" rel="noopener noreferrer" target="_blank">
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/crowdymessaging" rel="noopener noreferrer" target="_blank">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5, flexDirection: 'column' }}>
                            <img src="/assets/images/logo/AMSBlueGreenTextRigh.svg" alt="" style={{ height: 50 }} />
                            <p className="copy">
                                {`© ${new Date().getFullYear()} AMS SAS Todos los derechos reservados.`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;