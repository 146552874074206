import React, { useEffect } from 'react';
import Intro from './sms/Intro'
import Services from './sms/Services'
import SMS from './sms/SMS'
import Plans from './sms/Plans'
import ScrollUp from './home/ScrollUp'
import HowToSend from './sms/HowToSend'
import MessageType from './sms/messageType';


function Messaging() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ScrollUp showCalc={true}></ScrollUp>
      <div style={{ overflow: 'hidden' }}>
        <Intro></Intro>
        <SMS></SMS>
        <Services></Services>
        <HowToSend></HowToSend>
        <MessageType />
      </div>
      <Plans />
    </div>

  );
}

export default Messaging;
