import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from './NotFound';
import App from './App';
import Messaging from './Messaging';
import Automation from './Automation';
import Integrations from './Integrations';
import OTP from './otp';
import QR from './qr';
import ShortUrl from './shortUrl';
import Whatsapp from './whatsapp';

const Main = () => {

  return (

    <Switch>
      <Route exact path={`/`}>
        <App></App>
        <Redirect to={`/`} />
      </Route>
      <Route exact path={`/sms`} >
        <Messaging></Messaging>
        <Redirect to={`/sms`} />
      </Route>
      <Route exact path={`/automation`} >
        <Automation></Automation>
        <Redirect to={`/automation`} />
      </Route>
      <Route exact path={`/integrations`} >
        <Integrations></Integrations>
        <Redirect to={`/integrations`} />
      </Route>
      <Route exact path={`/otp`} >
        <OTP></OTP>
        <Redirect to={`/otp`} />
      </Route>
      <Route exact path={`/qr`} >
        <QR></QR>
        <Redirect to={`/qr`} />
      </Route>
      <Route exact path={`/shortUrl`} >
        <ShortUrl></ShortUrl>
        <Redirect to={`/shortUrl`} />
      </Route>
      <Route exact path={`/whatsapp`} >
        <Whatsapp></Whatsapp>
        <Redirect to={`/whatsapp`} />
      </Route>
      <Route exact path={`/*`} >
        <NotFound />        
      </Route>
    </Switch>
  );
}



export default Main;