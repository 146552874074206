import React from "react";
import FunctionCard from "../general/FunctionCard";
import intl from 'react-intl-universal'

function About() {

    const cardItems = [
        {
            anchor: 6,
            icon: 'typing',
            title: intl.get('home.apis.cards.auth.title'),
            detail: intl.get('home.apis.cards.auth.detail'),
            textAlign: 'center',
            detailHeight: 100,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'notification',
            title: intl.get('home.apis.cards.alerts.title'),
            detail: intl.get('home.apis.cards.alerts.detail'),
            textAlign: 'center',
            detailHeight: 100,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'map3',
            title: intl.get('home.apis.cards.sending.title'),
            detail: intl.get('home.apis.cards.sending.detail'),
            textAlign: 'center',
            detailHeight: 100,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'calendar',
            title: intl.get('home.apis.cards.events.title'),
            detail: intl.get('home.apis.cards.events.detail'),
            textAlign: 'center',
            detailHeight: 100,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'ecommerce',
            title: intl.get('home.apis.cards.marketing.title'),
            detail: intl.get('home.apis.cards.marketing.detail'),
            textAlign: 'center',
            detailHeight: 100,
            showAnimation: true
        }
    ]

    return (
        <div id="about" >

            <section className="section">
                <div className="container fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/api.svg`} alt="Multichannel API" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('home.apis.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="calculator-pricing-grid">
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <div className="card card-nohover" style={{ width: 'auto', background: '#00aab12e', boxShadow: '#6da4dc1a 0px 0px 20px 0px', padding: '4px 13px', color: 'black', flexDirection: 'row' }}>
                                <img src={`/assets/images/logo/platform.svg`} style={{ height: 25, paddingRight: 10 }} alt=""></img>
                                <p style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 11 }}>{intl.get('home.apis.integration.external')}</p>
                            </div>
                            <i className="fa fa-arrow-down" style={{ margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                            <div style={{ width: 'auto', background: '#fff', borderRadius: 10, boxShadow: 'rgb(134 154 175 / 25%) 0px 0px 20px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20 }} >
                                <div>
                                    <img src={`/assets/images/logo/crowdylogotextblack.svg`} style={{ height: 40, marginBottom: 15 }} alt=""></img>
                                </div>
                                <div className="card card-nohover" style={{ minWidth: 390, padding: '0 15px 5px' }} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={`/assets/images/logo/api.svg`} style={{ height: 25, paddingRight: 10 }} alt=""></img>
                                        <p style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 11 }}>{intl.get('home.apis.integration.api')}</p>
                                    </div>

                                    <div style={{ borderBottom: 0, display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '5px 10px', marginRight: 8, borderRadius: 10, boxShadow: 'rgba(134, 154, 175, 0.25) 0px 0px 20px 0px' }}>
                                            <img src={`/assets/images/logo/enterprisesmstextblue.svg`} style={{ height: 40 }} alt=""></img>
                                            {/* <p style={{ fontSize: 12, color: 'black', fontWeight: 600 }}>{'SMS'}</p> */}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '5px 10px', marginLeft: 8, borderRadius: 10, boxShadow: 'rgba(134, 154, 175, 0.25) 0px 0px 20px 0px' }}>
                                            <img src={`/assets/images/logo/WhatsappBottomTextColor.svg`} style={{ height: 40 }} alt=""></img>
                                            {/* <p style={{ fontSize: 12, color: 'black', fontWeight: 600 }}>{'WhatsApp'}</p> */}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '5px 10px', marginLeft: 8, borderRadius: 10, boxShadow: 'rgba(134, 154, 175, 0.25) 0px 0px 20px 0px' }}>
                                            <img src={`/assets/images/logo/authvalidatortextblue.svg`} style={{ height: 40 }} alt=""></img>
                                            {/* <p style={{ fontSize: 12, color: 'black', fontWeight: 600 }}>{'WhatsApp'}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <i className="fa fa-plus" style={{ margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                                <div className="card card-nohover" style={{ width: 'auto', background: 'rgb(90 162 244)', padding: '4px 13px', flexDirection: 'row' }} >
                                    <img src={`/assets/images/logo/fasturlnoborder.svg`} style={{ height: 20}} alt=""></img>
                                    {/* <p style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 11 }}>{intl.get('home.apis.integration.shorturl')}</p> */}
                                </div>
                                <i className="fa fa-arrow-down" style={{ margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                                <div className="card card-nohover" style={{ width: 'auto', background: '#4b8bd121', padding: '4px 13px', flexDirection: 'row' }} >
                                    <img src={`/assets/images/logo/shield.svg`} style={{ height: 25, paddingRight: 10 }} alt=""></img>
                                    <p style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 11 }}>{intl.get('home.apis.integration.gateway')}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginRight: 5 }}>

                                    <i className="fa fa-arrow-down" style={{ margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                                    <div className="card card-nohover" style={{ width: 'auto', background: '#a2cef8a3', boxShadow: '#6da4dc1a 0px 0px 20px 0px', padding: '4px 13px', color: 'black', flexDirection: 'row' }}>
                                        <img src={`/assets/images/logo/antenna.svg`} style={{ height: 25, padding: 0, marginRight: 10 }} alt=""></img>
                                        <p style={{ fontWeight: 600, fontSize: 11, textTransform: 'uppercase' }}>{intl.get('home.apis.integration.carriers')}</p>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginLeft: 5 }}>

                                    <i className="fa fa-arrow-down" style={{ margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                                    <div className="card card-nohover" style={{ width: 'auto', background: '#a2cef8a3', boxShadow: '#6da4dc1a 0px 0px 20px 0px', padding: '4px 13px', color: 'black', flexDirection: 'row' }}>
                                        <img src={`/assets/images/logo/meta.svg`} style={{ height: 25, padding: 4, marginRight: 10 }} alt=""></img>
                                        <p style={{ fontWeight: 600, fontSize: 11, textTransform: 'uppercase' }}>{intl.get('home.apis.integration.meta')}</p>

                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginRight: 5 }}>

                                    <i className="fa fa-arrow-down" style={{  margin: 5, background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', borderRadius: '50%', color: 'white', padding: 6, fontSize: 10 }}></i>
                                    <div className="card card-nohover" style={{ width: 'auto', background: '#a2cef8a3', boxShadow: '#6da4dc1a 0px 0px 20px 0px', padding: '4px 13px', color: 'black', flexDirection: 'row' }}>
                                        <img src={`/assets/images/logo/comment.svg`} style={{ height: 25, padding: 0, marginRight: 10 }} alt=""></img>
                                        <p style={{ fontWeight: 600, fontSize: 11, textTransform: 'uppercase' }}>{intl.get('home.apis.integration.client-device')}</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>
                            {cardItems.map((item, index) => (
                                <FunctionCard data={item} key={index} />
                            ))}
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
}

export default About;