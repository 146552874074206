import React, { useEffect, useState } from "react";
import SMSCalculator from '../calculator/SMSCalculator';
import intl from "react-intl-universal";

function Plans() {
    const [showFeatures, setShowFeatures] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);
    // const [calculatorAnimateOut, setCalculatorAnimateOut] = useState(false);

    const pricingDetail = [
        {
            anchor: 4,
            title: intl.get('sms.pricing.cards.cost.title'),
            icon: "pricing2",
            detail: intl.get('sms.pricing.cards.cost.detail'),
            btn: {
                // icon: 'pricing',
                text: intl.get('global.start-free'),
                className: 'blob-animate blue',
                action: () => {
                    window.frames["tidioChatApi"].open();
                    window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-sms-msg'));
                }
            }
        },
        {
            anchor: 4,
            title: intl.get('sms.pricing.cards.webportal.title'),
            icon: "webportal",
            detail: intl.get('sms.pricing.cards.webportal.detail'),
            url: {
                target: "https://docs.crowdymessaging.com",
                text: 'Explora nuestra documentación',
                icon: 'crowdydocs',
                newWindow: true
            }
        },
        {
            anchor: 4,
            title: intl.get('sms.pricing.cards.api.title'),
            icon: "cloudintegration",
            detail: intl.get('sms.pricing.cards.api.detail'),
            url: {
                target: "https://docs.crowdymessaging.com/docs/api/sms",
                text: 'Explora documentacion del API SMS',
                icon: 'crowdydocs',
                newWindow: true
            }
        },
        {
            anchor: 3,
            detailHeight: 90,
            title: intl.get('sms.pricing.cards.sla.title'),
            icon: "agentNew",
            detail: intl.get('sms.pricing.cards.sla.detail'),
        },
        {
            anchor: 3,
            detailHeight: 90,
            title: intl.get('sms.pricing.cards.volume.title'),
            icon: "volume",
            detail: intl.get('sms.pricing.cards.volume.detail'),
        },
    ]

    const detailsAndPricing = [
        {
            anchor: 2,
            detailHeight: 66,
            title: intl.get('sms.pricing.specs.title'),
            icon: "color-splash",
            detail: intl.get('sms.pricing.specs.detail'),
            showAnimation: true,
            showPointer: true,
            showActive: true,
            active: showFeatures,
            marginBottom: 5,
            onClick: () => {
                handleOptionItem(showFeatures, setShowFeatures)
            }
        },
        {
            anchor: 2,
            detailHeight: 66,
            title: intl.get('sms.pricing.calc.title'),
            icon: "calculator",
            detail: intl.get('sms.pricing.calc.detail'),
            showAnimation: true,
            showPointer: true,
            showActive: true,
            active: showCalculator,
            marginBottom: 5,
            onClick: () => {
                handleOptionItem(showCalculator, setShowCalculator)
            }
        },
    ]

    const handleOptionItem = (optionItem, optionHandler) => {
        if (!optionItem) {
            const closeFn = (item, handler) => {
                return new Promise((resolve, reject) => {
                    if (item) {
                        setAnimateOut(true);
                        setTimeout(() => {
                            handler(false);
                            setAnimateOut(false)
                            resolve();
                        }, 200);
                    }
                })

            }

            if (showFeatures) {
                closeFn(showFeatures, setShowFeatures)
                    .then(() => {
                        optionHandler(true)
                    });
            }
            else if (showCalculator) {
                closeFn(showCalculator, setShowCalculator)
                    .then(() => {
                        optionHandler(true)
                    });
            }
            else {
                optionHandler(true)
            }
        }
        else {
            setAnimateOut(true);
            setTimeout(() => {
                setAnimateOut(false);
                optionHandler(false)
            }, 200);
        }
    }

    const getCard = (data, index) => {
        return (
            <div key={index} {...data.onClick ? { onClick: data.onClick } : {}} className={`col-md-${data.anchor}`} style={data.showPointer ? { marginBottom: data.marginBottom !== undefined ? data.marginBottom : 20, cursor: 'pointer' } : { marginBottom: 20, }}>
                <div className={`card ${data.showAnimation ? '' : 'card-nohover'} ${data.active && data.showActive ? 'card-active' : ''}`} {...data.showActive ? { style: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } } : {}}>
                    <div>
                        <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 60 }} alt=""></img>
                    </div>
                    <div  {...data.detail ? {} : { style: { borderBottom: 'unset' } }} >
                        <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                            {`${data.title}`}
                        </p>
                    </div>
                    {data.detail &&
                        <div style={{ height: data.detailHeight ? data.detailHeight : 160 }}>
                            <p>
                                {`${data.detail}`}
                            </p>
                            {data.url &&
                                <a href={data.url.target} style={{ marginTop: 10 }} {...data.url.newWindow ? { target: '_blank' } : {}}>
                                    {data.url.icon ?
                                        <img src={`/assets/images/logo/${data.url.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.url.text}
                                </a>
                            }
                            {data.btn &&
                                <div onClick={data.btn.action} style={{ marginTop: 10 }} className={`button ${data.btn.className ? data.btn.className : ''}`}>
                                    {data.btn.icon ?
                                        <img src={`/assets/images/logo/${data.btn.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.btn.text}
                                </div>
                            }
                        </div>
                    }
                </div>
                {data.showActive &&
                    <div className={`animated ${animateOut ? 'zoomOut faster' : 'zoomIn fast'}`} style={{ display: data.active ? '' : 'none', animationDuration: '300ms' }}>
                        <div style={{ height: 4, background: `${data.active ? '#0f72c9' : 'transparent'}`, marginTop: 5, borderRadius: 2, transition: '200ms', animationDelay: '100ms' }}></div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 3, visibility: data.active ? 'visible' : 'hidden' }}>
                            <div style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', width: 30, height: 30, borderRadius: '50%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="fa fa-arrow-down" style={{ fontSize: 14 }}></i>
                            </div>
                        </div>
                    </div>

                }
            </div >
        )
    }

    const categories = [
        {
            categoryName: intl.get('sms.pricing.specs.features.global.title'), items: [
                { name: intl.get('sms.pricing.specs.features.global.extended'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.websend'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.reports'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.realtime'), basic: true, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.custom'), basic: true, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.schedule'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.deadline'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.sendwindow'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.templates'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.costcenter'), basic: true, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.groups'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.blacklist'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.shorturl'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.global.qr'), basic: false, business: true, enterprise: true },
            ]
        },
        {
            categoryName: intl.get('sms.pricing.specs.features.free.title'), items: [
                { name: intl.get('sms.pricing.specs.features.free.validator'), basic: false, business: true, enterprise: true },
            ]
        },
        {
            categoryName: intl.get('sms.pricing.specs.features.api.title'), items: [
                { name:intl.get('sms.pricing.specs.features.api.campaign'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.api.2fa'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.api.security'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.api.basicsec'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.api.shortener'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.api.external'), basic: false, business: false, enterprise: false },
                { name: intl.get('sms.pricing.specs.features.api.dev'), basic: false, business: false, enterprise: false },
            ]
        },
        {
            categoryName: intl.get('sms.pricing.specs.features.2fa.title'), items: [
                { name: intl.get('sms.pricing.specs.features.2fa.rbac'), basic: false, business: true, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.2fa.validator'), basic: false, business: true, enterprise: true },
            ]
        },
        // {
        //     categoryName: "Automatización BPM (+)", items: [
        //         { name: "Plataforma BPM con integración directa", basic: false, business: false, enterprise: false },
        //         { name: "Consultoria BPM e intergraciones avanzadas", basic: false, business: false, enterprise: false },
        //     ]
        // },
        {
            categoryName: intl.get('sms.pricing.specs.features.support.title'), items: [
                { name: intl.get('sms.pricing.specs.features.support.basic'), basic: true, business: true, enterprise: false },
                { name: intl.get('sms.pricing.specs.features.support.sla'), basic: true, business: true, enterprise: false },
                { name: intl.get('sms.pricing.specs.features.support.ext'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.support.slaext'), basic: false, business: false, enterprise: true },
                { name: intl.get('sms.pricing.specs.features.support.agent'), basic: false, business: false, enterprise: false },
            ]
        },
    ]

    useEffect(() => {
        var observer = new IntersectionObserver((records, observer) => {
            if (records[0].intersectionRatio === 0) {
                document.querySelector('#btn-custom').classList.remove('hide-button')
                document.querySelector('#sticky-container').parentNode.classList.add('header-sticky')
            }
            else if (records[0].intersectionRatio === 1) {
                document.querySelector('#sticky-container').parentNode.classList.remove('header-sticky')
            }
        }, { threshold: [0] });

        observer.observe(document.querySelector('#sticky-container'))

        return(()=>{
            observer.disconnect()
        })

    }, []);

    return (
        <>
            <div id="plans" >
                <section className="section alt-section">
                    <div className="container wow fadeIn faster">
                        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                                <h2>{intl.get('sms.pricing.title')}</h2>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="container wow fadeIn faster" style={{ margin: '0px 5% 0px 5%', width: '90%' }}>
                        <div className="row" style={{ marginBottom: 10 }}>
                            <div className="col-md-12" style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                                {pricingDetail.map(getCard)}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div id="calc" >
                <section className="section">
                    <div className="container wow fadeIn faster">
                        <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                                <h2>{intl.get('sms.pricing.title2')}</h2>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row" style={{ marginBottom: 10 }}>
                            <div className="col-md-12" style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                                {detailsAndPricing.map(getCard)}
                            </div>
                            <div className="compare-container">
                                <div className={`col-md-12 animated ${animateOut ? 'fadeOut' : 'fadeIn'} faster compare-table`} style={{ marginTop: 0, display: showFeatures ? 'unset' : 'none' }}>
                                    <div style={{ height: 1 }} id="sticky-container"></div>
                                    <table>
                                        <thead>
                                            <tr >
                                                <th><h5>{intl.get('sms.pricing.specs.title')}</h5></th>
                                                <th className="custom">
                                                    <button id="btn-custom" className={`hide-button animated fadeIn fast blob-animate blue`} onClick={() => {
                                                        window.frames["tidioChatApi"].open();
                                                        window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-sms-msg'));
                                                    }}>
                                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                                        {intl.get('global.start-free')}
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories.map((item, catIndex) => {
                                                return (
                                                    <React.Fragment key={catIndex}>
                                                        <tr style={{ background: 'none' }}>
                                                            <td colSpan={5} style={{ fontWeight: 'bold', paddingLeft: 10 }}><h6><img style={{ height: 18, marginTop: -5 }} src={`/assets/images/serviceslogos/007-bulleye.svg`} alt='' />{` ${item.categoryName}`}</h6></td>
                                                        </tr>
                                                        {item.items.map((feature, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{feature.name}</td>
                                                                    <td><img src={`/assets/images/serviceslogos/check.svg`} alt='' /></td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </React.Fragment>
                                                )

                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <p>{intl.getHTML('sms.pricing.specs.features.footer')}</p>
                                </div>
                                <div className={`col-md-12 animated ${animateOut ? 'fadeOut' : 'fadeIn'} faster compare-table`} style={{ marginTop: 0, display: showCalculator ? 'unset' : 'none' }}>
                                    <SMSCalculator />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Plans;