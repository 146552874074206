import React, { useEffect } from 'react'

function Services() {

    const serviceList = [
        {
            Title: "Enlaces con su marca",
            Icon: "undraw_Logo_design.svg",
            Detail: "Las personas hacen clic en enlaces en los que confían. Con nuestra solución puede crear enlaces con su dominio aumentando sus clics hasta en un 34%."
        },
        {
            Title: "Redirección personalizada",
            Icon: "undraw_people_search.svg",
            Detail: "Cambie la Url genérica y cree enlaces personalizados para saber la efectividad con cada uno de sus clientes."
        },
        {
            Title: "Estadísticas",
            Icon: "undraw_dashboard.svg",
            Detail: "Aproveche cada clic y almacene datos geográficos, impacto, numero de aperturas, para tomar decisiones inteligentes con datos reales sobre como y donde posicionar su marca."
        },
    ]

    useEffect(() => {
        window.$("#owl-services").owlCarousel({
            items: 3,
            navigation: true,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            autoPlay: 10000,
            singleItem: false
        });
    })

    const renderServiceItem = (item, i) => {
        return (
            <div key={Math.random()} className="owl-item">
                <div className="col-sm-12">
                    <div className="service">
                        <div className="icon">
                            <img src={`/assets/images/illustrations/${item.Icon}`} style={{ height: 200, marginBottom: 10 }} alt="" />
                        </div>
                        {item.Url ?
                            <a href={item.Url} rel="noopener noreferrer" target="_blank">
                                <h4>{item.Title}</h4>
                            </a>
                            :
                            <h4>{item.Title}</h4>
                        }
                        <div className="text">
                            <p >{item.Detail}</p>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <section id="services" className="section alt-section">
            <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                <div className="row">
                    <div className="col-md-12">
                        <div id="owl-services" className="owl-carousel">
                            {serviceList.map(renderServiceItem)}
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Services
