import React, { useEffect, useRef } from 'react'
import intl from 'react-intl-universal'
import { Link } from "react-router-dom";

function Services() {

    useEffect(() => {
        var observer = new IntersectionObserver((records, observer) => {
            let target = stickyDivRef.current;
            if (target) {
                if (records[0].intersectionRatio === 0) {
                    targetStickyDivRef.current.classList.add('services-sticky')
                }
                else if (records[0].intersectionRatio === 1) {
                    targetStickyDivRef.current.classList.remove('services-sticky')
                }
            }
        }, { threshold: [0] });

        let target = stickyDivRef.current;
        if (target) {
            observer.observe(stickyDivRef.current)
        }
        return (() => {
            if (observer)
                observer.disconnect()
        })

    }, []);

    const stickyDivRef = useRef();
    const targetStickyDivRef = useRef();

    const serviceList = [
        {
            anchor: 3,
            icon: "enterprisesms",
            iconHeight: 50,
            detail: intl.get('home.services.enterprisesms'),
            textAlign: 'center',
            background: "radial-gradient(#084498e3, #084498)",
            detailHeight: 140,
            showAnimation: true,
            url: {
                text: intl.get('global.more'),
                target: '/sms',
                newWindow: true
            }
        },
        {
            anchor: 3,
            icon: "whatsappbusinesslogo",
            iconHeight: 50,
            background: "radial-gradient(#46ae39e3, #46ae39)",
            detail: intl.get('home.services.whatsapp'),
            textAlign: 'center',
            detailHeight: 140,
            showAnimation: true,
            url: {
                text: intl.get('global.more'),
                target: '/whatsapp',
                newWindow: true
            }
        },
        {
            anchor: 3,
            icon: "fasturl",
            iconHeight: 50,
            // background: "radial-gradient(#b3b712e8, #b3b712)",
            background: "radial-gradient(#921a43e8, #921a43)",
            detail: intl.get('home.services.fasturl'),
            textAlign: 'center',
            detailHeight: 140,
            showAnimation: true,
            url: {
                text: intl.get('global.more'),
                target: '/shorturl',
                newWindow: true
            }
        },
        {
            anchor: 3,
            icon: "authvalidator",
            iconHeight: 50,
            background: 'radial-gradient(#38a9e2e0, #38a9e2)',
            detail: intl.get('home.services.authvalidator'),
            textAlign: 'center',
            detailHeight: 140,
            showAnimation: true,
            url: {
                text: intl.get('global.more'),
                target: '/otp',
                newWindow: true
            }
        }
    ]

    const getCard = (data, index) => {
        return (
            <div key={index} {...data.onClick ? { onClick: data.onClick } : {}} className={`col-md-${data.anchor}`} style={data.showPointer ? { marginBottom: data.marginBottom !== undefined ? data.marginBottom : 0, cursor: 'pointer' } : { marginBottom: 0, }}>
                <div className={`card ${data.showAnimation ? 'prod-hover' : 'card-nohover'} ${data.active && data.showActive ? 'card-active' : ''}`} {...data.showActive ? { style: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, background: data.background } } : { style: { background: data.background } }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', filter: 'drop-shadow(2px 4px 6px #00000040)', paddingBottom: 0, marginBottom: -10 }}>
                        <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: data.iconHeight, marginRight: data.iconText ? 15 : 0 }} alt=""></img>
                    </div>
                    {data.title ?
                        <div  {...data.detail ? {} : { style: { borderBottom: 'unset' } }} >
                            <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                                {`${data.title}`}
                            </p>
                        </div>
                        : <div style={{ display: 'none' }}>

                        </div>
                    }
                    {data.detail &&
                        <div style={{ height: data.detailHeight ? data.detailHeight : 160, transition: '200ms' }}>
                            <p className="sticky-hide" style={{ textAlign: data.textAlign ? data.textAlign : 'left', color: 'white' }}>
                                {`${data.detail}`}
                            </p>
                            {data.url &&
                                // <Link to={data.url.target} style={{ marginTop: 10, background: '#ffffff33' }} {...data.url.newWindow ? { target: '_blank' } : {}}>
                                <Link to={data.url.target} style={{ marginTop: 10, background: '#ffffff33' }}>
                                    {data.url.icon ?
                                        <img src={`/assets/images/logo/${data.url.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.url.text}
                                </Link>
                                // <a href={data.url.target} style={{ marginTop: 10, background: '#ffffff33' }} {...data.url.newWindow ? { target: '_blank' } : {}}>
                                //     {data.url.icon ?
                                //         <img src={`/assets/images/logo/${data.url.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                //         :
                                //         <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                //     }
                                //     {data.url.text}
                                // </a>
                            }
                            {data.btn &&
                                <div onClick={data.btn.action} style={{ marginTop: 10 }} className={`button ${data.btn.className ? data.btn.className : ''}`}>
                                    {data.btn.icon ?
                                        <img src={`/assets/images/logo/${data.btn.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.btn.text}
                                </div>
                            }
                        </div>
                    }
                </div>
                {
                    data.showActive &&
                    <div className={`animated zoomIn fast`} style={{ display: data.active ? '' : 'none', animationDuration: '300ms' }}>
                        <div style={{ height: 4, background: `${data.active ? '#0f72c9' : 'transparent'}`, marginTop: 5, borderRadius: 2, transition: '200ms', animationDelay: '100ms' }}></div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 3, visibility: data.active ? 'visible' : 'hidden' }}>
                            <div style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', width: 30, height: 30, borderRadius: '50%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="fa fa-arrow-down" style={{ fontSize: 14 }}></i>
                            </div>
                        </div>
                    </div>

                }
            </div >
        )
    }

    return (
        <>
            <div id="services" style={{ position: 'relative' }}>
                <div style={{ height: 1, position: 'absolute', top: 80 }} ref={stickyDivRef}></div>
            </div>
            <section ref={targetStickyDivRef} className="section sticky-section alt-section animated fadeIn faster" style={{ top: -80, zIndex: 100, overflowX: 'hidden', minHeight: 360, transition: '100ms' }}>
                <div className="container fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/social.svg`} style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} alt="" />
                            <h2>{intl.get('home.services.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container fadeIn faster">
                    <div className="row">
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>
                            {serviceList.map(getCard)}
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Services
