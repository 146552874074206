import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const SliderItem = (props) => {
    const unitTypes = [
        { label: 'U', tooltip: 'Unidades', value: 1, maxSlider: 1000, sliderStep: 10 },
        { label: 'K', tooltip: 'Miles', value: 1000, maxSlider: 1000, sliderStep: 10 },
        { label: 'M', tooltip: 'Millones', value: 1000000, maxSlider: 100, sliderStep: 0.5 },
    ]

    const [selectedUnitType, setSelectedUnitType] = useState(props.data.unitType);
    const [value, setValue] = useState(props.data.value);
    const [sliderMax, setSliderMax] = useState(unitTypes[props.data.unitType].maxSlider);
    const [sliderStep, setSliderStep] = useState(unitTypes[props.data.unitType].sliderStep);
    const [marks, setMarks] = useState(
        [
            {
                value: 0,
                label: '0',
            },
            {
                value: 1000,
                label: Intl.NumberFormat(props.currencyItem.locale,
                    {
                        notation: "compact",
                        compactDisplay: "short",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 5,
                    }).format(1000000).replace(/\s/g, ''),
            },
        ])


    const useStyles = makeStyles({
        root: {
            width: 'calc(100% - 20px)',
            color: '#3c89ea',
            margin: '0 10px 10px'
        },
        valueLabel: {
            left: 'calc(-50% - 6px)',
            fontSize: 10,
        },
        markLabel: {
            padding: 5,
            fontSize: 10,
            fontWeight: 600
        }
    });

    const classes = useStyles();

    useEffect(() => {
        if (props.onChange)
            props.onChange(props.data.index, value, selectedUnitType, unitTypes[selectedUnitType].value)
    })

    return (
        <>
            <div style={{ display: 'flex', width: '100%' }}>
                <h5 style={{ margin: '10px 0px 10px 0px', width: '100%', fontSize: 13, textTransform: 'unset' }}>
                    <img src={`/assets/images/logo/${props.data.icon}.svg`} style={{ height: 28 }} alt=""></img>
                    {props.data.shortLabel}</h5>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
                <div className="calculator-currency" style={{ width: '100%', justifyContent: 'center' }}>
                    <div style={{ marginBottom: 0, background: 'transparent', border: '1px solid #3c89ea' }}>
                        {'Cantidad'}
                    </div>
                    <div className="active" style={{ marginBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, width: 60, position: 'relative' }}>
                        <input type="number" style={{ outline: 'none', border: 'unset', textAlign: 'center', margin: 0, background: 'transparent', width: value < 10 ? 23 : value >= 10 && value < 100 ? 32 : value >= 100 && value < 1000 ? 27 : 36 }} min={0} max={sliderMax} value={value} onChange={event => {
                            let number = parseFloat(event.target.value);
                            if (!isNaN(number) && number <= sliderMax) {
                                setValue(number);
                                event.target.value = number
                            }
                            else {
                                setValue(0)
                            }
                        }} />
                        <span>
                            {selectedUnitType !== 0 ? unitTypes[selectedUnitType].label : ''}
                        </span>
                    </div>
                </div>
                <div className="calculator-currency" style={{ width: '100%', justifyContent: 'center' }}>
                    <div style={{ marginBottom: 0, background: 'transparent', border: '1px solid #3c89ea' }}>
                        {'Escala'}
                    </div>
                    {unitTypes.map((item, index) => (
                        <div key={index} onClick={() => {

                            let newMarks = [...marks];
                            newMarks[1].value = item.maxSlider;
                            newMarks[1].label = Intl.NumberFormat(props.currencyItem.locale,
                                {
                                    notation: "compact",
                                    compactDisplay: "short",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 5,
                                }).format(item.maxSlider * unitTypes[index].value).replace(/\s/g, '');

                            setMarks(newMarks)

                            setSelectedUnitType(index);
                            setSliderMax(item.maxSlider);
                            setSliderStep(item.sliderStep);
                            if (index === 2 && value > 50) {
                                setValue(50)
                                if (props.onChange)
                                    props.onChange(props.data.index, 50, index, item.value)
                            }
                        }} style={{ marginBottom: 0 }} className={`hover-detail ${selectedUnitType === index ? 'active' : ''}`}>{item.label}
                            <span >
                                <span>
                                    <span>
                                        {item.tooltip}
                                    </span>
                                </span>
                            </span>
                        </div>
                    ))}

                </div>
            </div>
            <div style={{ width: '100%' }}>
                <Slider classes={classes}
                    max={sliderMax}
                    aria-labelledby="discrete-slider-always"
                    step={sliderStep}
                    marks={props.showMarks ? marks : undefined}
                    value={value}
                    valueLabelFormat={(value) => {
                        return Intl.NumberFormat(props.currencyItem.locale,
                            {
                                notation: "compact",
                                compactDisplay: "short",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 5,
                            }).format(value * unitTypes[selectedUnitType].value).replace(/\s/g, '').toUpperCase();
                    }}
                    onChange={(event, newValue) => {
                        if (newValue !== value) {
                            setValue(newValue)
                            if (props.onChange)
                                props.onChange(props.data.index, newValue, selectedUnitType, unitTypes[selectedUnitType].value)
                        }
                    }}
                    valueLabelDisplay="auto"
                />
            </div>
        </>
    )
}

export default SliderItem;