import React, { useEffect, useRef } from "react";
import SliderItem from "./SliderItem";

const MultiValueItem = (props) => {
    const currencyItem = props.smsPricingbyCountry.find(item => item['alpha-2'] === props.selectedCountry['alpha-2']).pricingList[props.selectedCurrency];
    const stickyRef = useRef();
    console.info(JSON.stringify(props.data))
    // console.info(JSON.stringify(currencyItem))

    // const unitTypes = [
    //     { label: 'U', tooltip: 'Unidades', value: 1, maxSlider: 1000, sliderStep: 10 },
    //     { label: 'K', tooltip: 'Miles', value: 1000, maxSlider: 1000, sliderStep: 10 },
    //     { label: 'M', tooltip: 'Millones', value: 1000000, maxSlider: 100, sliderStep: 1 },
    // ]

    // const [selectedUnitType, setSelectedUnitType] = useState(props.unitType);
    // const [value, setValue] = useState(props.value);
    // const [sliderMax, setSliderMax] = useState(unitTypes[props.unitType].maxSlider);
    // const [sliderStep, setSliderStep] = useState(unitTypes[props.unitType].sliderStep);
    // const [marks, setMarks] = useState(
    //     [
    //         {
    //             value: 0,
    //             label: '0',
    //         },
    //         {
    //             value: 1000,
    //             label: Intl.NumberFormat(currencyItem.locale,
    //                 {
    //                     notation: "compact",
    //                     compactDisplay: "short",
    //                     minimumFractionDigits: 0,
    //                     maximumFractionDigits: 5,
    //                 }).format(1000000).replace(/\s/g, ''),
    //         },
    //     ])


    // useEffect(() => {
    //     if (props.onChange)
    //         props.onChange(value, selectedUnitType, unitTypes[selectedUnitType].value)
    // })

    useEffect(() => {
        let target = stickyRef.current;

        const observer = new IntersectionObserver((records, observer) => {
            if (target) {
                if (records[0].intersectionRatio === 0) {
                    target.parentNode.classList.add('header-group-sticky')
                }
                else if (records[0].intersectionRatio === 1) {
                    target.parentNode.classList.remove('header-group-sticky')
                }
            }
        }, { threshold: [0] });

        if (target) {
            observer.observe(target)
        }
    }, [])

    // const additionalItem = currencyItem.pricingItems.find(item => item.id === props.data.id);
    // var price = (props.volumeDiscount ? currencyItem.value - currencyItem.volumeDiscount : currencyItem.value) + (additionalItem ? additionalItem.additionalCost : 0);

    return (
        <div className="col-md-12" style={{
            margin: '8px 0',
            padding: '10px 20px',
            background: '#4b8bd10d',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <div style={{ height: 1, position: 'absolute', top: -212 }} ref={stickyRef}></div>
            <h5 style={{ padding: '8px 0px', margin: 0,  fontSize: 13, width: 'calc(100% + 40px)', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'sticky', top: 70 + 167 }}>
                <img src={`/assets/images/logo/${props.groupData.icon}.svg`} style={{ height: 28, marginTop: 0 }} alt=""></img>
                {props.groupData.label}</h5>
            {/* <p style={{ textTransform: 'uppercase', fontSize: 12, marginTop: 8, marginBottom: 0 }}><strong>ESTIMACIÓN: </strong>{`${Intl.NumberFormat(currencyItem.locale,
                {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 5,
                }).format(value * unitTypes[selectedUnitType].value).replace(/\s/g, '')} ${props.data.shortLabel} = ${currencyItem.currency} ${Intl.NumberFormat(currencyItem.locale,
                    {
                        style: 'currency',
                        currency: currencyItem.currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 5,
                    }).format(price * value * unitTypes[selectedUnitType].value)}`}</p>
            <p style={{ textTransform: 'uppercase', fontSize: 12, marginTop: 0, marginBottom: 0 }}><strong>COSTO: </strong>
                {`${currencyItem.currency} ${Intl.NumberFormat(currencyItem.locale,
                    {
                        style: 'currency',
                        currency: currencyItem.currency,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 5,
                    }).format(price)} / ${additionalItem ? props.data.additionalDetail : 'SMS'}`
                }
            </p> */}
            {props.data.map((data, index) => (
                <SliderItem key={index}
                    currencyItem={currencyItem}
                    unitType={data.unitType}
                    value={data.value}
                    data={data}
                    onChange={props.onChange}
                    showMarks={false}></SliderItem>
            ))}
        </div>
    )
}

export default MultiValueItem;