import React, { useEffect } from 'react';
import Intro from './home/Intro'
import Services from './home/Services'
import ScrollUp from './home/ScrollUp'
import Status from './home/Stats'
import About from './home/About'
import Features from './home/Features'
import Integration from './home/Integration';


function App() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <div>
      <ScrollUp></ScrollUp>
      <Intro></Intro>
      <Services></Services>
      <About></About>
      <Features />
      <Integration />
      <Status />
    </div>

  );
}

export default App;
