import React from 'react'
import intl from "react-intl-universal";

function Services() {

    const serviceList = [
        {
            title: intl.get('sms.specs.schedule.title'),
            icon: "calendar",
            detail: intl.get('sms.specs.schedule.detail'),
            detailHeight: 90,
        },
        {
            title: intl.get('sms.specs.limit.title'),
            icon: "calendar2",
            detail: intl.get('sms.specs.limit.detail'),
            detailHeight: 90,
        },
        {
            title: intl.get('sms.specs.validate.title'),
            icon: "clientsource",
            detail: intl.get('sms.specs.validate.detail'),
            detailHeight: 90,
        },
        {
            title: intl.get('sms.specs.ftp.title'),
            icon: "ftp",
            detail: intl.get('sms.specs.ftp.detail'),
        },
        {
            title: intl.get('sms.specs.url.title'),
            icon: "shorturl",
            detail: intl.get('sms.specs.url.detail'),
            url: "/shorturl",
            urlText: intl.get('global.service-more'),
        },
        {
            title: intl.get('sms.specs.2fa.title'),
            icon: "typing",
            detail: intl.get('sms.specs.2fa.detail'),
            url: "/otp",
            urlText: intl.get('global.service-more'),
        },
        {
            title: intl.get('sms.specs.dash.title'),
            icon: "automation",
            detail: intl.get('sms.specs.dash.detail'),
            detailHeight: 90,
        },
        {
            title: intl.get('sms.specs.cert.title'),
            icon: "cert",
            detail: intl.get('sms.specs.cert.detail'),
            detailHeight: 90,
        },
        // {
        //     title: "Centros de costo",
        //     icon: "costcenter",
        //     detail: "Administra tu consumo en centros de costo, controla tu operación y costos generando facturación global o independiente ",
        //     detailHeight: 90,
        // },
    ]

    const getCard = (data, index) => {
        return (
            <div key={index} className='col-md-4' style={{ marginBottom: 20 }}>
                <div className="card card-nohover">
                    <div>
                        <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 60 }} alt=''></img>
                    </div>
                    <div>
                        <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                            {`${data.title}`}
                        </p>
                    </div>
                    <div style={{ height: data.detailHeight ? data.detailHeight : '' }}>
                        <p>
                            {`${data.detail}`}
                        </p>
                        {data.url &&
                            <a href={data.url} style={{ marginTop: 10 }}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {/* <img src={`/assets/images/login.svg`} alt="Portal de Cliente" style={{ height: 36, marginRight: 5 }} /> */}
                                {data.urlText}
                            </a>
                        }
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div id="services">
            <section className="section">
                <div className="container" >
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/color-splash.svg`} alt="" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('sms.specs.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-md-12" style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                            {serviceList.map(getCard)}
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}

export default Services
