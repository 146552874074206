import React, { useEffect } from 'react';
import Intro from './qr/Intro'
import Services from './qr/Services'
import About from './qr/About'
import ScrollUp from './home/ScrollUp'


function QR() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ScrollUp></ScrollUp>
      <div style={{ overflow: 'hidden' }}>
        <Intro></Intro>
        <Services></Services>
      </div>
      <About></About>
    </div>

  );
}

export default QR;
