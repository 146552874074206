import React from "react";
import intl from "react-intl-universal";

function HowToSend() {
    const [stepHover, setStepHover] = React.useState(0);
    const getCard = (data) => {
        return (
            <div className={`card ${stepHover === parseInt(data.step) ? 'active' : ''}`} onMouseOver={() => { setStepHover(parseInt(data.step)) }} onMouseLeave={() => { setStepHover(0) }} style={{ marginBottom: 10 }}>
                <div>
                    <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 48 }} alt=""></img>
                </div>
                <div>
                    <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                        {`${data.title}`}
                    </p>
                </div>
                <div style={{ height: 80 }}>
                    <p>
                        {`${data.detail}`}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div id="howtosned">
            <section className="section alt-section">
                <div className="container" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/webportal.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('sms.sending.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ animationDelay: '400ms' }}>
                    <div className="row" style={{ marginTop: 20, marginBottom: 20 }}>
                        <div className='col-md-3 wizard-card wizard-card-one' onMouseOver={() => { setStepHover(parseInt(1)) }} onMouseLeave={() => { setStepHover(0) }}>
                            <div className={`${stepHover === 1 ? 'active' : ''}`}>
                                <div className="blob blue" style={{ top: 5, right: 14, zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16, width: 28, height: 28 }}>1</div>
                                <img src={`/assets/images/sendwizard/send-wizard-1.webp`} alt=""/>
                            </div>
                        </div>
                        <div className='col-md-3 wizard-card wizard-card-two' onMouseOver={() => { setStepHover(parseInt(2)) }} onMouseLeave={() => { setStepHover(0) }}>
                            <div className={`${stepHover === 2 ? 'active' : ''}`}>
                                <div className="blob yellow" style={{ top: 5, right: 14, zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16, width: 28, height: 28 }}>2</div>
                                <img src={`/assets/images/sendwizard/send-wizard-2.webp`} alt="" />
                            </div>
                        </div>
                        <div className='col-md-3 wizard-card wizard-card-three' onMouseOver={() => { setStepHover(parseInt(3)) }} onMouseLeave={() => { setStepHover(0) }}>
                            <div className={`${stepHover === 3 ? 'active' : ''}`}>
                                <div className="blob green" style={{ top: 5, right: 14, zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16, width: 28, height: 28 }}>3</div>
                                <img src={`/assets/images/sendwizard/send-wizard-3.webp`} alt=""/>
                            </div>
                        </div>

                    </div>
                    <div className="row" style={{ padding: '25px 0px' }}>
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        step: '1',
                                        title: intl.get('sms.sending.step1.title'),
                                        color: 'rgba(52, 172, 224, 1)',
                                        icon: 'contacts',
                                        detail: intl.get('sms.sending.step1.detail')
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        step: '2',
                                        title: intl.get('sms.sending.step2.title'),
                                        color: 'rgba(255, 177, 66, 1)',
                                        icon: 'txtbox',
                                        detail: intl.get('sms.sending.step2.detail')
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        step: '3',
                                        title: intl.get('sms.sending.step3.title'),
                                        color: 'rgb(39 178 145)',
                                        icon: 'chatsend',
                                        detail: intl.get('sms.sending.step3.detail')
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HowToSend;