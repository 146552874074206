import React from 'react'
import intl from 'react-intl-universal'

function Intro() {

    return (
        <section id="intro" className="section animated fadeIn faster" style={{ backgroundImage: "url('/assets/images/fasturl.svg')", backgroundSize: "cover", backgroundPosition: "center", height: 450 }}>
            <div className="container" >
                <div className="row">
                    <div className="col-md-8">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 270 }}>
                            <img src="/assets/images/logo/fasturl.svg" style={{ width: 350, marginBottom: 30 }} alt=''></img>
                            <h1 style={{ textShadow: 'rgb(0 0 0 / 74%) 7px 1px 20px', textTransform: 'uppercase', margin: '0 10% 30px', fontSize: 16 }}>
                                Comunique en menos palabras y genere un impacto contundente de manera personalizada.
                            </h1>
                            <button className={`button blob-animate blue-header`} style={{ fontSize: 14 }} onClick={() => {
                                window.frames["tidioChatApi"].open();
                                window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-global-msg'));
                            }}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {intl.get('global.start-free')}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeIn crowdy-portal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                        <dotlottie-player autoplay
                            src={`/assets/lottie/headers/fasturl4.lottie`}
                            style={{ marginTop: -75, height: 400 }}></dotlottie-player>
                        {/* <lottie-player
                            src={`/assets/lottie/Chat_WhatsApp.json`}
                            background="transparent" speed="1" style={{ marginTop: -50, height: 400 }} autoplay loop></lottie-player> */}
                        {/* <img src={`/assets/images/illustrations/whatsapp_ilustration.svg`} style={{ maxWidth: '90%' }} alt="" /> */}
                    </div>
                    {/* <div className="col-md-8">
                        <div className="hello wow zoomIn faster">
                            <br></br>
                            <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px', textTransform: 'none', margin: '0 10%' }}>
                                Agregue seguridad a su operación, nuestro proceso genera los códigos y envía el mensaje a sus usuarios finales con un SLA especializado.
                            </h3>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeIn crowdy-portal" style={{ animationDelay: '500ms' }}>
                        <img src={`/assets/images/illustrations/undraw_prototyping_process_rswj.svg`} style={{ maxWidth: '90%' }} alt="" />
                    </div>
                    <div className="col-md-12">
                        <a href="#about">
                            <div className="mouse-icon" style={{ marginTop: -50 }}>
                                <div className="wheel"></div>
                            </div>
                        </a>

                    </div> */}
                </div>
            </div>
        </section>
        // <section id="intro" className="section" style={{ background: 'linear-gradient(135deg, #261675 0%, #00abff 140%)' }}>


        //     <div className="container" >
        //         <div className="row">
        //             <div className="col-md-8">
        //                 <div className="hello wow fadeIn faster">
        //                     <br></br>
        //                     <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px', textTransform: 'none', margin: '0 10%' }}>
        //                         Comunique en menos palabras y genere un impacto contundente de manera personalizada.
        //                     </h3>
        //                 </div>
        //             </div>
        //             <div className="col-md-4 wow fadeIn crowdy-portal" style={{ animationDelay: '500ms' }}>
        //                 <img src={`/assets/images/illustrations/undraw_link_shortener.svg`} style={{ maxWidth: '90%' }} alt="" />
        //             </div>
        //             <div className="col-md-12">
        //                 <a href="#about">
        //                     <div className="mouse-icon" style={{ marginTop: -50 }}>
        //                         <div className="wheel"></div>
        //                     </div>
        //                 </a>

        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}

export default Intro;