import React from "react";
import intl from 'react-intl-universal'
import FunctionCard from "../general/FunctionCard";

function Stats() {
    const cardItems = [
        {
            anchor: 6,
            icon: 'bar',
            title: intl.get('home.stats.historical.title'),
            detail: intl.get('home.stats.historical.detail'),
            textAlign: 'center',
            detailHeight: 60,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'crowdylogo',
            title: intl.get('home.stats.availavility.title'),
            detail: intl.get('home.stats.availavility.detail'),
            textAlign: 'center',
            detailHeight: 60,
            showAnimation: true
        }
    ]

    return (
        <div>

            <section id="stats" className="section alt-section">
                <div className="container fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/crowdylogo.svg`} alt="" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('home.stats.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="calculator-pricing-grid">
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>
                            {cardItems.map((item, index) => (
                                <FunctionCard data={item} key={index} />
                            ))}
                            <a href="https://stats.uptimerobot.com/KEwArHqnEY"
                                target="_blank"
                                rel="noreferrer noopener" style={{ marginTop: 10, height: 34, textDecoration: 'none', color: 'white' }} className={`button`}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {intl.get('home.stats.goTo')}
                            </a>
                        </div>
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <dotlottie-player
                                src={`/assets/lottie/status-bot.lottie`}
                                background="transparent" speed="1" style={{ with: 300, display: "flex", justifyContent: "center" }} autoplay loop></dotlottie-player>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Stats;