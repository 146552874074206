import React from 'react'
import intl from 'react-intl-universal'

function Intro() {
    

    return (
        <section id="intro" className="section animated fadeIn faster" style={{ backgroundImage: "url('/assets/images/whatsapp.svg')", backgroundSize: "cover", backgroundPosition: "center", height: 450 }}>
          
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 270 }}>
                            <img src="/assets/images/logo/whatsappbusinesslogo.svg" style={{ width: 350, marginBottom: 30 }} alt=''></img>
                            <h1 style={{ textShadow: 'rgb(0 0 0 / 74%) 7px 1px 20px', textTransform: 'uppercase', margin: '0 10% 30px', fontSize: 16 }}>
                                Automatice procesos a través de la API de WhatsApp, principalmente para el soporte central y los centros de llamadas en empresas medianas y grandes.
                            </h1>
                            <button className={`button blob-animate blue-header`} style={{ fontSize: 14 }} onClick={() => {
                                window.frames["tidioChatApi"].open();
                                window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-global-msg'));
                            }}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {intl.get('global.start-free')}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeIn crowdy-portal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <dotlottie-player autoplay loop
                            src={`/assets/lottie/headers/whatsapp.lottie`}
                            style={{ marginTop: -50, height: 400 }}></dotlottie-player>
                        {/* <lottie-player
                            src={`/assets/lottie/Chat_WhatsApp.json`}
                            background="transparent" speed="1" style={{ marginTop: -50, height: 400 }} autoplay loop></lottie-player> */}
                        {/* <img src={`/assets/images/illustrations/whatsapp_ilustration.svg`} style={{ maxWidth: '90%' }} alt="" /> */}
                    </div>
                    {/* <div className="col-md-12">
                        <a href="#about">
                            <div className="mouse-icon" style={{ marginTop: -80 }}>
                                <div className="wheel"></div>
                            </div>
                        </a>

                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default Intro;