import React, { useEffect, useRef, useState } from 'react'

function ScrollUp(props) {

    const scrollTopRef = useRef()
    const [animateOut, setAnimateOut] = useState(false)

    const scrollTopFnc = () => {
        if (window.scrollY > 100) {
            setAnimateOut(false)
            scrollTopRef.current.style.display = "flex";
            // scrollTopRef.current.style.opacity = "1"
        } else {
            setAnimateOut(true)
            // scrollTopRef.current.style.opacity = "0"
            // scrollTopRef.current.style.display = "none";
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollTopFnc);
        return (() => {
            window.removeEventListener('scroll', scrollTopFnc)
        })
    })

    return (
        <>
            <div ref={scrollTopRef} className={`scroll-up animated ${animateOut ? 'fadeOut' : 'zoomInLeft'}`} style={{ animationDuration: `${animateOut? '400ms': '600ms'}`, display: 'none', bottom: props.showCalc ? 254 : 190 }}>
                <a href="#intro">
                    <i className="fa fa-arrow-up"></i>
                </a>
            </div>
            {props.showCalc &&
                <div className="whatsapp-float animated zoomInLeft fast" style={{ bottom: 190 }}>
                    <a href={`#calc`}>
                        <img src={`/assets/images/logo/calculator.svg`} alt="" style={{ height: 42, filter: 'drop-shadow(0px 2px 7px rgba(12, 70, 52, 0.5))' }} />
                    </a>
                </div>
            }
            <div className="whatsapp-float animated zoomInLeft fast">
                <a href="https://bit.ly/433exiF" target="_blank" rel="noopener noreferrer">
                    <img src={`/assets/images/serviceslogos/whatsapp.svg`} alt="" style={{ filter: 'drop-shadow(0px 2px 7px rgba(12, 70, 52, 0.5))' }} />
                </a>
            </div>
        </>
    );
}

export default ScrollUp;