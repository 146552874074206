import React from "react";
import EmojiText from '../iphone-mockup/EmojiText';
import moment from "moment";
import intl from "react-intl-universal";

function MessageType() {

    const urlify = (text) => {
        var urlRegex = /(\s{1}\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)*[-A-Z0-9]{3,}[.]{1}[-A-Z0-9]{2,}[/]*[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]*)/igm;
        // var urlRegex = /(\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)*[-A-Z0-9+&@#\/%?=~_|!:,.;]+\/[-A-Z0-9+&@#\/%=~_|]+)/ig;
        // var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, (url) => {
            let target = "#";
            return `<a href="${target}" onclick="return false;" target="_blank" class="phone-mockup-link">${url}</a>`;
        });
        // or alternatively
        // return text.replace(urlRegex, `<a href="${props.enableLinks ? /\b(https:\/\/|http:\/\/|ftp:\/\/|file:\/\/)/ig.test("\\$1") ? "$1".replace('\s', '') : "http://$1" : "#"}" ${props.enableLinks ? "" : 'onclick="return false;"'} target="_blank">$1</a>`)
    }

    const serviceList = [
        {
            title: intl.get('sms.types.2fa.title'),
            message: intl.get('sms.types.2fa.msg'),
            detail: intl.get('sms.types.2fa.detail'),
            detailHeight: 78
        },
        {
            title: intl.get('sms.types.notifications.title'),
            message: intl.get('sms.types.notifications.msg'),
            detail: intl.get('sms.types.notifications.detail'),
            detailHeight: 78
        },
        {
            title: intl.get('sms.types.season.title'),
            message: intl.get('sms.types.season.msg'),
            detail: intl.get('sms.types.season.detail'),
            detailHeight: 78
        },
        {
            title: intl.get('sms.types.events.title'),
            message: intl.get('sms.types.events.msg'),
            detail: intl.get('sms.types.events.detail'),
            detailHeight: 78
        },

        // {
        //     title: "campañas de bienvenida",
        //     message: "👋 Recibe hasta 50% off en tu primera compra activando tu código 34216 😎. Oferta válida hasta el próximo fin de semana! conoce más en cdy.ly/welcome",
        //     detail: "Envía ofertas de descuentos para atraer a tus clientes a su primera interación después de un registro satisfactorio."
        // },
        {
            title: intl.get('sms.types.cart.title'),
            message: intl.get('sms.types.cart.msg'),
            detail: intl.get('sms.types.cart.detail'),
            detailHeight: 78
        },
        {
            title: intl.get('sms.types.additional.title'),
            message: intl.get('sms.types.additional.msg'),
            detail: intl.get('sms.types.additional.detail'),
            detailHeight: 78
        }
    ]

    const getMessage = (data) => {
        return (
            <div style={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', maxHeight: '95%', overflow: 'hidden' }}>
                <div style={{ width: '82%', height: 6, position: 'absolute', bottom: 1, backgroundColor: '#f0f3f8', borderRadius: '0 0 10px 10px' }}>
                </div>
                <div style={{ width: '88%', height: 6, position: 'absolute', bottom: 7, backgroundColor: '#e9edf5', borderRadius: '0 0 10px 10px' }}>
                </div>
                <div style={{ width: '94%', height: 'calc(100% - 14px)', position: 'absolute', top: '1%', backgroundColor: '#e4e8f2', borderRadius: 10 }}>
                </div>
                <div style={{ width: '37%', position: 'absolute', left: 12, top: 8, display: 'inline-table' }}>
                    <img alt="" src="/assets/img-mockup/IMessage_logo.svg" style={{ width: '19%', position: 'absolute', top: '2%', left: '4%' }} />
                    <p style={{ fontFamily: 'SFRegular', paddingLeft: '28%', fontSize: 10, fontWeight: 700, color: '#0000007d', paddingTop: '5%', margin: 0 }}>
                        {intl.get('global.msg')}
                    </p>
                </div>
                <div style={{ width: '63%', position: 'absolute', right: 20, top: 8, textAlign: 'right' }}>
                    <p style={{ fontFamily: 'SFRegular', fontSize: 10, fontWeight: 700, color: '#0000007d', paddingTop: '3%', margin: 0 }}>
                        {moment().fromNow()}
                    </p>
                </div>
                <div style={{ width: '100%', color: 'black', zIndex: 1, padding: '2% 7% 8%', display: 'grid' }}>
                    <p style={{ fontWeight: 'bold', fontFamily: 'SFRegular', margin: 0, paddingTop: '11%', fontSize: 11 }} >
                        {data.code}
                    </p>
                    {/* <CrowdyScrollbar vertical smoothScrolling={false}> */}
                    <p
                        dangerouslySetInnerHTML={
                            { __html: EmojiText.parse(urlify(data.messageText, true), false, 17, 'facebook') }
                        }
                        style={{ overflow: 'scroll', position: 'relative', paddingTop: '1%', paddingRight: 12, margin: '1px -11px -15px 0px', fontFamily: 'SFRegular', fontSize: 12, marginTop: 1, fontWeight: 'bold', lineHeight: 1.35 }}>
                    </p>
                    {/* </CrowdyScrollbar> */}
                </div>
            </div>
        )
    }

    const getCard = (data, index) => {
        return (
            <div key={index} className='col-md-4' style={{ marginBottom: 20 }}>
                <div className="card card-nohover">
                    <div style={{ height: 165 }}>
                        {getMessage({ messageText: data.message, code: data.code })}
                        {/* <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 60 }}></img> */}
                    </div>
                    <div>
                        <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                            {`${data.title}`}
                        </p>
                    </div>
                    <div style={{ height: data.detailHeight ? data.detailHeight : 'unset' }}>
                        <p style={{ textAlign: 'left' }}>
                            {`${data.detail}`}
                        </p>
                        {data.url &&
                            <a href={data.url} style={{ marginTop: 10 }}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {/* <img src={`/assets/images/login.svg`} alt="Portal de Cliente" style={{ height: 36, marginRight: 5 }} /> */}
                                {data.urlText}
                            </a>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="messageType">
            <section className="section">
                <div className="container">
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/social.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('sms.types.title')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container" style={{ animationDelay: '400ms' }}>
                    <div className="row">

                        <div className="container">
                            <br />
                            <div className="row">
                                <div className="col-md-12" style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                                    {serviceList.map(getCard)}
                                </div>

                            </div>

                            <br />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MessageType;