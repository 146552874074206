import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";
import EmojiText from '../iphone-mockup/EmojiText';
import intl from 'react-intl-universal';

function MainHeader() {
    const navbarRef = useRef();

    const scrollTopFnc = () => {
        // var navHeight = navbarRef.current.offsetHeight;
        if (window.scrollY >= 70) {
            navbarRef.current.classList.add('navbar-color');
        }
        else {
            navbarRef.current.classList.remove('navbar-color');
        }
    }

    const resizeFnc = () => {
        if (window.innerWidth <= 767) {
            navbarRef.current.classList.add('custom-collapse');
        }
        else {
            navbarRef.current.classList.remove('custom-collapse');
        }
    }

    let location = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', scrollTopFnc);

        resizeFnc();
        window.addEventListener('resize', resizeFnc)

        window.$(document).on('click', '.navbar-collapse.in', function (e) {
            if (window.$(e.target).is('a') && window.$(e.target).attr('class') !== 'dropdown-toggle') {
                window.$(this).collapse('hide');
            }
        });

        window.$('a[href^=#]').bind("click", function (e) {
            var anchor = window.$(this);
            if (window.$(anchor.attr('href')).offset()) {
                window.$('html, body').stop().animate({
                    scrollTop: window.$(anchor.attr('href')).offset().top
                }, 1000);
            }
            e.preventDefault();
        });

    })

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'UA-233311525-1');

    }, [])


    return (
        <nav ref={navbarRef} className="navbar navbar-custom navbar-fixed-top" role="navigation">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#custom-navbar-collapse-1">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <Link to="/" className="navbar-brand" style={{ paddingTop: 15 }}>
                        <div style={{ float: 'left', marginTop: -21 }}>
                            <img src={`/assets/images/logo/crowdy-logo-ams-white.svg`} alt="Crowdy Messaging" />
                        </div>
                        {/* <div className='ams-header'>
                            <p>
                                by
                                <img src="/assets/images/logo/AMSBlueGreenTextRigh.svg" alt="AMS" />
                            </p>
                        </div> */}
                    </Link>
                    {/* <a className="navbar-brand" href="/" style={{ paddingTop: 15 }}>
                       
                    </a> */}
                </div>

                <div className="collapse navbar-collapse" id="custom-navbar-collapse-1">
                    <ul className="nav navbar-nav navbar-right " style={{ fontWeight: 'bold', textShadow: 'rgb(39 48 85 / 66%) 7px 1px 20px' }}>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {intl.get('header.products.title')}
                            </span>
                            <div className="dropdown-menu products-highlight animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                <ul className="nav">
                                    {!location.pathname.endsWith("/sms") &&
                                        <li style={{ background: '#084498' }}>
                                            <Link to="sms">
                                                <img src={`/assets/images/logo/enterprisesms.svg`} alt="Mensajería Empresarial" style={{ height: 30, marginLeft: -28, marginRight: 8, marginBottom: 6 }} />
                                                {/* {intl.get('header.products.sms.title')} */}
                                                <p style={{ color: 'white' }}>{intl.get('header.products.sms.detail')}</p>
                                            </Link>

                                        </li>
                                    }
                                    {!location.pathname.endsWith("/whatsapp") &&
                                        <li style={{ background: '#46ae39' }}>
                                            <Link to="whatsapp">
                                                <img src={`/assets/images/logo/whatsappbusinesslogo.svg`} alt="whatsapp" style={{
                                                    height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6
                                                    // , filter: 'drop-shadow(#26354a22 0px 2px 1px)' 
                                                }}
                                                />
                                                {/* {intl.get('header.products.whatsapp.title')} */}
                                                <p style={{ color: 'white' }}>{intl.get('header.products.whatsapp.detail')}</p>
                                            </Link>
                                        </li>
                                    }
                                    {/* {location.pathname.endsWith("/automation" &&
                                        <li>
                                        <Link to="/automation">
                                        <img src={`/assets/images/logo/RhinoApp.svg`} alt="Automatización" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                        {' Automatización'}
                                        <p>Automatice procesos BPM usando nuestros canales</p>
                                        </Link>
                                        </li>
                                    } */}


                                    {!location.pathname.endsWith("/otp") &&
                                        <li style={{ background: '#38a9e2' }}>
                                            <Link to="otp">
                                                <img src={`/assets/images/logo/authvalidator.svg`} alt="OTP" style={{ height: 30, marginLeft: -37, marginRight: 8, marginBottom: 6 }} />
                                                {/* {intl.get('header.products.otp.title')} */}
                                                <p style={{ color: 'white' }}>{intl.get('header.products.otp.detail')}</p>
                                            </Link>
                                        </li>
                                    }
                                    {/* {!location.pathname.endsWith("/qr") &&
                                        <li>
                                            <Link to="qr">
                                                <img src={`/assets/images/logo/qrCode.svg`} alt="qr" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                {' Crowdy QR Scan'}
                                                <p>Crea códigos QR personalizados con tu marca</p>
                                                </Link>
                                                </li>
                                            } */}
                                    {!location.pathname.endsWith("/shortUrl") &&
                                        <li style={{ background: '#921a43' }}>
                                            <Link to="shortUrl">
                                                <img src={`/assets/images/logo/fasturl.svg`} alt="shortUrl" style={{ height: 30, marginLeft: -60, marginRight: 8, marginBottom: 6 }} />
                                                {/* {intl.get('header.products.shorturl.title')} */}
                                                <p style={{ color: 'white' }}>{intl.get('header.products.shorturl.detail')}</p>
                                            </Link>
                                        </li>
                                    }


                                </ul>
                            </div>
                        </li>


                        {/* {location.pathname.endsWith("/") &&
                            <>
                                <li>
                                    <a href="#about">Nosotros</a>
                                </li>
                                <li>
                                    <a href="#stats" rel="noopener noreferrer" target="_blank">
                                        {intl.get('header.status')}
                                    </a>
                                </li>
                            </>
                        } */}

                        {location.pathname.endsWith("/sms") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {intl.get('header.products.sms.sections.title')}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#whysms" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/comment.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.products.sms.sections.why.title')}
                                                    <p>{intl.get('header.products.sms.sections.why.detail')}</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#services" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/color-splash.svg`} alt="Características" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.products.sms.sections.specs.title')}
                                                    <p>{intl.get('header.products.sms.sections.specs.detail')}</p>
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="#integration" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/cloudintegration.svg`} alt="Integración" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {' Integración'}
                                                    <p>¿Cómo integrarse con nustros servicios?</p>
                                                </a>
                                            </li> */}
                                            <li>
                                                <a href="#howtosned" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/webportal.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.products.sms.sections.send.title')}
                                                    <p>{intl.get('header.products.sms.sections.send.detail')}</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#messageType" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/social.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.products.sms.sections.strategy.title')}
                                                    <p>{intl.get('header.products.sms.sections.strategy.detail')}</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://docs.crowdymessaging.com/docs/platform/charset#-herramienta-de-validaci%C3%B3n" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/txtbox.svg`} alt="Valida tus mensajes" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.products.sms.sections.validate.title')}
                                                    <p>{intl.get('header.products.sms.sections.validate.detail')}</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {intl.get('header.pricing.title')}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#plans" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/pricing2.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.pricing.general.title')}
                                                    <p>{intl.get('header.pricing.general.detail')}</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#calc" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/calculator.svg`} alt="Por qué SMS" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                                    {intl.get('header.pricing.calc.title')}
                                                    <p>{intl.get('header.pricing.calc.detail')}</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li>
                                    <a href="#plans" rel="noopener noreferrer" target="_blank">
                                        {' Precios'}
                                    </a>
                                </li> */}
                            </React.Fragment>
                        }

                        {location.pathname.endsWith("/integrations") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {'Integraciones'}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#about" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/suitcase.svg`} alt="Servicios" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Servicios'}
                                                    <p>¿Cómo podemos ayudarte?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </React.Fragment>
                        }

                        {/* {location.pathname.endsWith("/automation") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {'Automatización'}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#about" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/statistics.svg`} alt="Características" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Características'}
                                                    <p>¿Por qué usar nuestros servicios de automatización?</p>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#whybpm" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/RhinoApp.svg`} alt="Por qué BPM" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Por qué BPM'}
                                                    <p>¿Por qué automatizar con Business Process Management?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </React.Fragment>
                        } */}

                        {location.pathname.endsWith("/shortUrl") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {'Crowdy Fast URL'}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#about" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/statistics.svg`} alt="Características" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Características'}
                                                    <p>¿Por qué usar nuestros servicios de Short Url?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </React.Fragment>
                        }

                        {location.pathname.endsWith("/qr") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {'Crowdy QR Scan'}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#about" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/statistics.svg`} alt="Características" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Características'}
                                                    <p>¿Por qué usar nuestros servicios QR?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </React.Fragment>
                        }

                        {location.pathname.endsWith("/otp") &&
                            <React.Fragment>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {'Crowdy OTP'}
                                    </span>
                                    <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                        <ul className="nav">
                                            <li>
                                                <a href="#about" rel="noopener noreferrer" target="_blank">
                                                    <img src={`/assets/images/logo/statistics.svg`} alt="Características" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                                    {' Características'}
                                                    <p>¿Por qué usar nuestros servicios de OTP?</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        <li>
                            <a href="#contact">{intl.get('header.contactus')}</a>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {intl.get('header.support.title')}
                            </span>
                            <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                <ul className="nav">
                                    <li>
                                        <a href="https://docs.crowdymessaging.com/faq" rel="noopener noreferrer" target="_blank">
                                            <img src={`/assets/images/serviceslogos/FAQ.svg`} alt="Preguntas frecuentes" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                                            {intl.get('header.support.faq.title')}
                                            <p>{intl.get('header.support.faq.detail')}</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://crowdyteam.atlassian.net/servicedesk/customer/portals" rel="noopener noreferrer" target="_blank">
                                            <img src={`/assets/images/serviceslogos/jiraservicemanagement.svg`} alt="Portal de Cliente" style={{ height: 36, padding: 4, marginRight: 8, marginBottom: 6 }} />
                                            {intl.get('header.support.jira.title')}
                                            <p>{intl.get('header.support.jira.detail')}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {intl.get('header.devs.title')}
                            </span>
                            <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" >
                                <ul className="nav">
                                    <li>
                                        <a href="https://docs.crowdymessaging.com" rel="noopener noreferrer" target="_blank">
                                            <img src={`/assets/images/logo/crowdydocs.svg`} alt="Portal de Documentación" style={{ height: 30, marginLeft: 3, marginRight: 3, marginBottom: 6 }} />
                                            {intl.get('header.devs.docs.title')}
                                            <p>{intl.get('header.devs.docs.detail')}</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://stats.uptimerobot.com/KEwArHqnEY" rel="noopener noreferrer" target="_blank">
                                            <img src={`/assets/images/serviceslogos/007-bulleye.svg`} alt="Portal de Cliente" style={{ height: 36, padding: 4, marginBottom: 6 }} />
                                            {intl.get('header.devs.status.title')}
                                            <p>{intl.get('header.devs.status.detail')}</p>
                                        </a>
                                    </li>
                                    {!location.pathname.endsWith("/integrations") &&
                                        <li>
                                            <Link to="integrations">
                                                <img src={`/assets/images/logo/code.svg`} alt="Integraciones" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6, filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 3px)' }} />
                                                {intl.get('header.products.integrations.title')}
                                                <p>{intl.get('header.products.integrations.detail')}</p>
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </li>


                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ padding: 12, display: 'flex' }}>
                                <img src={`/assets/images/logo/language.svg`} alt="Portal de Cliente" style={{ height: 28, marginRight: 5 }} />
                                <p className='lang-label'
                                    dangerouslySetInnerHTML={
                                        { __html: EmojiText.getFlagEmojiByISOName(window.currentLocale.isoCode, 20, '🌎', 'google').innerHTML + `&nbsp&nbsp${window.currentLocale.isoCode}` }
                                    }>
                                </p>
                            </span>
                            <div className="dropdown-menu animated fadeIn faster" aria-labelledby="navbarDropdown" style={{ padding: 0 }}>
                                <ul className="nav" >
                                    {window.supportedLocales.map((item, index) => {
                                        // if (item.value !== window.currentLocale.value) {
                                        return (
                                            < li key={index} style={item.isoCode === window.currentLocale.isoCode ? { background: '#346ebc4a' } : { cursor: 'pointer' }} >
                                                <span className={`${item.isoCode === window.currentLocale.isoCode ? 'active' : ''}`} style={{ color: '#a3133b', display: 'flex', alignItems: 'center', fontSize: 12 }}
                                                    onClick={item.isoCode !== window.currentLocale.isoCode ? () => {
                                                        debugger;
                                                        localStorage['lang'] = item.isoCode;
                                                        let urlSplit = window.location.href.split('/');
                                                        let index = urlSplit.findIndex(item => item === window.currentLocale.isoCode);
                                                        urlSplit[index] = item.isoCode;
                                                        let newUrl = urlSplit.join('/');
                                                        window.location.href = newUrl;
                                                    } : () => { }}
                                                    dangerouslySetInnerHTML={
                                                        { __html: EmojiText.getFlagEmojiByISOName(item.isoCode, 25, '🏳️', 'google').innerHTML + `&nbsp&nbsp${intl.get(`locales.${item.code}`)}` }
                                                    }>
                                                </span>
                                            </li>
                                        )
                                        // }
                                    })}
                                </ul>
                            </div>
                        </li>
                        <li>
                            <a href="https://portal.crowdymessaging.com" className="blue-button skip-text-transform" rel="noopener noreferrer" target="_blank">
                                <img src={`/assets/images/login.svg`} alt="Portal de Cliente" style={{ height: 36, marginRight: 5 }} />
                                {intl.get('global.login')}
                                {/* <button type="button">Ingresar</button> */}
                            </a>
                        </li>
                    </ul>
                </div >
            </div >
        </nav >
    );
}
export default MainHeader