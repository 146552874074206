import React from 'react'
import intl from 'react-intl-universal'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

function Intro() {
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const getHome = () => {
        return (
            <div className="row" style={{ padding: '25px 80px 0' }}>
                <div className="col-md-8">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 0, height: 400 }}>
                        <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px' }}>Bienvenido</h3>
                        <br />
                        <h1 style={{ textShadow: 'rgb(0 0 0 / 74%) 7px 1px 20px', textTransform: 'uppercase', margin: '0 10%', fontSize: 16 }}>
                            {intl.get('home.intro.title')}
                        </h1>
                        <br />
                        <button className={`button blob-animate blue`} style={{ fontSize: 14, transition: 'unset' }} onClick={() => {
                            window.frames["tidioChatApi"].open();
                            window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-global-msg'));
                        }}>
                            <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                            {intl.get('global.start-free')}
                        </button>

                    </div>
                </div>
                <div className="col-md-4 " style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', height: 400 }}>
                    <dotlottie-player autoplay
                        src={`/assets/lottie/headers/Hero section REN 2.lottie`}
                        style={{}}></dotlottie-player>
                </div>
            </div>
        )
    }

    const getWhatsAppGuide = () => {
        return (
            <div className="row" style={{ padding: '25px 80px 0' }}>
                <div className="col-md-8">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 0, height: 400 }}>
                        <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px' }}>Guía de estrategia de crecimiento conversacional</h3>
                        <br />
                        <h1 style={{ textShadow: 'rgb(0 0 0 / 74%) 7px 1px 20px', textTransform: 'uppercase', margin: '0 10%', fontSize: 16 }}>
                            Descubre qué es una estrategia de crecimiento conversacional, cómo beneficia tanto a los clientes como a las marcas y cómo crear una para tu empresa.
                        </h1>
                        <br />
                        <a href='https://business.whatsapp.com/blog/conversational-growth-strategy' className='button blue' style={{ fontSize: 14, transition: 'unset', textDecoration: 'none' }} target='_blank' rel="noopener noreferrer">
                            <img src={`/assets/images/logo/whatsapp.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                            {/* <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i> */}
                            {'Leer más'}
                        </a>
                        {/* <button className={`button blob-animate blue`} style={{ fontSize: 14, transition: 'unset' }} onClick={() => {
                            window.frames["tidioChatApi"].open();
                            window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-global-msg'));
                        }}>
                            <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                            {intl.get('global.start-free')}
                        </button> */}

                    </div>
                </div>
                <div className="col-md-4 " style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', height: 400 }}>
                    <dotlottie-player autoplay
                        src={`/assets/lottie/headers/faq6.lottie`}
                        style={{ height: 300 }}></dotlottie-player>
                </div>
            </div>
        )
    }

    return (
        <section id="intro" className="section animated fadeIn faster" style={{ backgroundImage: "url('/assets/images/home2.svg')", backgroundSize: "cover", backgroundPosition: "center", height: 450 }}>
            <AutoplaySlider play={true}
                className="slider-styles"
                organicArrows={true}
                cancelOnInteraction={false}
                interval={10000}
                fillParent={true}
            >
                {getHome()}
                {getWhatsAppGuide()}
            </AutoplaySlider>
        </section>
    );
}

export default Intro;