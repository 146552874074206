import React, { useEffect } from 'react';
import Intro from './whatsapp/Intro'
import Services from './whatsapp/Services'
import Features from './whatsapp/Features'
import ScrollUp from './home/ScrollUp'


function Whatsapp() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <ScrollUp></ScrollUp>
      <div style={{ overflow: 'hidden' }}>
        <Intro></Intro>
        <Services></Services>
        <Features></Features>
      </div>
    </div>

  );
}

export default Whatsapp;
