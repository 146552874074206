import React, { useEffect } from "react"
import ScrollUp from "./home/ScrollUp";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ScrollUp></ScrollUp>
            <div style={{ overflow: 'hidden' }}>
                <section id="intro" className="section alt-section" style={{ backgroundImage: "url('/assets/images/new background.svg')", backgroundSize: "cover", backgroundPosition: "center", height: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                </section>

                <section className="section alt-section rotate-section" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <dotlottie-player
                        src={`/assets/lottie/404.lottie`}
                        background="transparent" speed="0.8" loop style={{ width: 350, height: 350, display: "flex", justifyContent: "center", overflow: 'hidden', marginTop: -45 }} autoplay></dotlottie-player>
                    <p style={{ textTransform: 'uppercase', marginTop: -70 }}>{intl.getHTML('global.404')}</p>
                    <Link to="/" style={{ paddingTop: 15, display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <p className="button blue" style={{ fontSize: 13 }}>
                            <img src={`/assets/images/logo/home.svg`} style={{ height: 18, marginLeft: 3, marginRight: 8 }} alt="" />
                            {intl.get('global.home')}
                        </p>
                    </Link>
                </section>

            </div>
        </div>
    )
}

export default NotFound;