import React from "react";
import intl from "react-intl-universal";

function Contact() {
    // let refreshId = -1;
    // let innerWidth = window.innerWidth;
    // let innerHeight = window.innerHeight;

    // const refreshParallax = () => {
    //     if (refreshId === -1) {

    //         window.setTimeout(() => {
    //             if (window.innerWidth !== innerWidth || window.innerHeight !== innerHeight)
    //                 refreshId = window.setTimeout(() => {
    //                     window.$('#products-header').parallax('refresh');
    //                     window.$('#products-header').parallax('render');
    //                     refreshId = -1;
    //                     innerWidth = window.innerWidth;
    //                     innerHeight = window.innerHeight;
    //                 }, 100)
    //         }, 200)
    //     }
    // }

    // useEffect(() => {
    //     window.$('#contact-header').parallax({ src: `/assets/images/parallax-contact.jpg` })

    //     window.addEventListener("resize", refreshParallax);
    //     window.addEventListener("scroll", refreshParallax);
    // });


    return (
        <div id="contact">
            {/* <section id="contact-header" className="section-header">
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 animated bounceInDown">
                                <h2 style={{ fontWeight: 'bold' }}>Contactanos</h2>
                                <p >Envíanos una mensaje o llámanos. Nos encantará escucharte.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="contact" className="section alt-section">
                <div className="container  wow fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2 style={{ fontWeight: 'bold' }}>{intl.get('contactus.title')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-md-5 illustration" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }} >
                            <dotlottie-player className="wow"
                                src={`/assets/lottie/60968-contact-center-blue.lottie`}
                                background="transparent" speed="1" style={{ width: 250, height: 250, display: "flex", justifyContent: "center" }} autoplay></dotlottie-player>
                            {/* <img src={`/assets/images/illustrations/undraw_contact_us_15o2.svg`} style={{ height: 420, maxWidth: '80%' }} alt="" /> */}
                        </div>
                        <div className="col-md-7 " style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 420 }}>

                            {/*                             <div className="service">
                                <div className="icon">
                                    <img src={`/assets/images/serviceslogos/tidio.svg`} style={{ height: 36 }} alt="" />
                                </div>
                                <div className="text">
                                    <address>
                                        <a href="https://www.tidio.com/talk/pqrnftxxl4kyalylyzwadzxlbkvcvt92" rel="noopener noreferrer" target="_blank">Live Chat</a>
                                    </address>
                                </div>
                            </div> */}
                            <div style={{
                                width: '80%', background: '#e6edf6', borderRadius: 10, position: 'relative',
                                // boxShadow: '0px 0px 20px 0px #6da4dc1a'
                            }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'left' }}>
                                    <img src={`/assets/images/logo/crowdylogotextblack.svg`} style={{ marginLeft: 20, marginTop: 10, height: 40, marginRight: 10 }} alt="" />
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: -20 }}>
                                    <div className="service" style={{ padding: 10 }}>
                                        <div className="icon">
                                            <img src={`/assets/images/serviceslogos/whatsapp.svg`} style={{ height: 36 }} alt="" />
                                        </div>
                                        <div className="text">
                                            <address style={{ margin: 0 }}>
                                                <a href="https://bit.ly/433exiF" target="_blank" rel="noreferrer noopener">WhatsApp</a>
                                            </address>
                                        </div>
                                    </div>
                                    <div className="service" style={{ padding: 10 }}>
                                        <div className="icon">
                                            <img src={`/assets/images/serviceslogos/tidio.svg`} style={{ height: 36 }} alt="" />
                                        </div>
                                        <div className="text">
                                            <address style={{ margin: 0 }}>
                                                <a href="https://www.tidio.com/talk/pqrnftxxl4kyalylyzwadzxlbkvcvt92" rel="noopener noreferrer" target="_blank">{intl.get('contactus.live-chat')}</a>
                                            </address>
                                        </div>
                                    </div>
                                </div>
                                <div className="service" style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 13 }}>
                                    <div className="icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={`/assets/images/logo/at.svg`} style={{ height: 22, marginRight: 10 }} alt="" />
                                        <h4 style={{ margin: 0 }}>{intl.get('contactus.email')}</h4>
                                    </div>
                                    <div className="text">
                                        <address style={{ margin: 0 }}>
                                            <a href="mailto:sales@crowdymessaging.com">sales@crowdymessaging.com</a>
                                        </address>
                                    </div>
                                </div>

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', borderRadius: '0 0 10px 10px', marginBottom: 20 }}>
                                    <div className="service" style={{ padding: 13 }}>
                                        <div className="icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                            <img src={`/assets/images/logo/phone.svg`} style={{ height: 22, marginRight: 10 }} alt="" />
                                            <h4 style={{ margin: 0 }}>{intl.get('contactus.phones')}</h4>
                                        </div>
                                        <div className="text">
                                            <address style={{ margin: 0 }}>
                                                {/* <a href="tel:+57 (304) 382-6377"><abbr title="Colombia">+57</abbr> (304) 382-6377</a>
                                            <br /> */}
                                                {/* <a href="tel:+57 (1) 659-3990"><abbr title="Colombia">+57</abbr> (1) 659-3990</a>
                                            <br /> */}
                                                <a href="tel:+57 (319) 568-3623"><abbr title="Colombia">+57</abbr> (319) 568-3623</a>
                                                <br />
                                                <a href="tel:+57 (317) 832-6058"><abbr title="Colombia">+57</abbr> (317) 832-6058</a>
                                                {/* <br /> */}
                                            </address>
                                        </div>
                                    </div>
                                    <div className="service" style={{ padding: 13 }}>
                                        <div className="icon" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                            <img src={`/assets/images/logo/map3.svg`} style={{ height: 22, marginRight: 10 }} alt="" />
                                            <h4 style={{ margin: 0 }}>{intl.get('contactus.address')}</h4>
                                        </div>
                                        <div className="text">
                                            <address style={{ margin: 0 }}>
                                                {'CL 150 # 10 - 48 Of 101'}
                                                <br /> {'Bogotá - Colombia'}
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '75%', height: 8, display: 'flex', justifyContent: 'center', background: '#e6edf6', borderRadius: '0 0 10px 10px', opacity: 0.5 }}></div>
                            <div style={{ width: '70%', height: 8, display: 'flex', justifyContent: 'center', background: '#e6edf6', borderRadius: '0 0 10px 10px', opacity: 0.3 }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Contact;