import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/owl.theme.css';
import './assets/css/owl.carousel.css';
import './assets/css/animate.css';
import './assets/css/font-awesome.css';
import './assets/css/app.css';
import './assets/css/emoji-mart.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main.js'
import MainHeader from './home/MainHeader'
import Footer from './home/Footer'
import Contact from './home/Contact'
import intl from 'react-intl-universal';
import moment from "moment"
import "moment/locale/es"
import "moment/locale/pt"


const supportedLocales = [
  {
    value: "es",
    moment: "es",
    code: "es-CO",
    isoCode: 'co'
  },
  {
    value: "es",
    moment: "es",
    code: "es-MX",
    isoCode: 'mx'
  },
  {
    value: "en",
    moment: "en",
    code: "en-US",
    isoCode: 'us'
  },
  // {
  //   value: "en",
  //   code: "en-GB",
  //   isoCode: 'gb'
  // },
  {
    value: "pt",
    moment: "pt",
    code: "pt-BRA",
    isoCode: 'br'
  },
  // {
  //   value: "es",
  //   code: "fr-FR",
  //   isoCode: 'fr'
  // },

];

// determine locale by storage key or default browser language
let locale = intl.determineLocale({ localStorageLocaleKey: 'lang' });

var supportedLangIndex = supportedLocales.findIndex(item => item.code === locale || item.isoCode === locale)

// determineLocale by url
let urlSplit = window.location.href.split('/');
let urlSupportedLangIndex;
let urlIndex = urlSplit.findIndex(urlPart => {
  let found = supportedLocales.findIndex(item => item.isoCode === urlPart)
  if (found !== -1)
    urlSupportedLangIndex = found;
  return supportedLocales.findIndex(item => item.isoCode === urlPart) !== -1
});

// check if url locale don't match with storage key or browser language
if (urlIndex !== -1) {
  if (urlSupportedLangIndex !== supportedLangIndex) {
    supportedLangIndex = urlSupportedLangIndex
    // localStorage['lang'] = supportedLocales[urlSupportedLangIndex].isoCode;
    // urlSplit[urlIndex] = supportedLocales[urlSupportedLangIndex].isoCode;
    // let newUrl = urlSplit.join('/');
    // window.location.href = newUrl
  }
  // else{
  //   window.location.href = window.location.origin
  // }
}

const currentLocale = supportedLocales[supportedLangIndex !== -1 ? supportedLangIndex : 0]; // Determine user's locale here
intl.init({
  currentLocale: currentLocale.value,
  locales: {
    [currentLocale.value]: require(`./locales/${currentLocale.value}`)
  }
});

window.currentLocale = currentLocale;
window.supportedLocales = supportedLocales;
moment.locale(currentLocale.moment)

// meta tags
document.documentElement.setAttribute("lang", currentLocale.code);
document.documentElement.setAttribute("xml:lang", currentLocale.code);
document.querySelector('meta[name="description"]').setAttribute("content", intl.get('meta.description'));
document.title = intl.get('meta.title')

supportedLocales.forEach((item, index) => {
  if (index === 0) {
    let canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', window.location.origin + '/' + item.isoCode)
    canonical.setAttribute('hreflang', item.code);
    document.head.appendChild(canonical)
  }
  else {
    let link = document.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('href', window.location.origin + '/' + item.isoCode)
    link.setAttribute('hreflang', item.code);
    document.head.appendChild(link)
  }
})

ReactDOM.render(
  // <BrowserRouter >
  <BrowserRouter basename={`/${window.currentLocale.isoCode}`}>
    <MainHeader></MainHeader>
    <Main />
    <Contact></Contact>
    <Footer></Footer>
  </BrowserRouter>
  ,
  document.getElementById('reactRoot')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

document.tidioChatLang = window.currentLocale.value;
// (function () {
// function onTidioChatApiReady() {
//   // window.tidioChatApi.open();
//   debugger;
//   // document.tidioChatLang = currentLocale.value;
// }
// if (window.tidioChatApi) {
//   window.tidioChatApi.on('ready', onTidioChatApiReady);
// } else {
//   document.addEventListener('tidioChat-ready', onTidioChatApiReady);
// }
// })();