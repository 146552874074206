import React from "react"

const FunctionCard = (props) => {
    return (
        <div key={props.key} {...props.data.onClick ? { onClick: props.data.onClick } : {}} className={`col-md-${props.data.anchor}`} style={props.data.showPointer ? { marginBottom: props.data.marginBottom !== undefined ? props.data.marginBottom : 20, cursor: 'pointer' } : { marginBottom: 20, }}>
            <div className={`card ${props.data.showAnimation ? '' : 'card-nohover'} ${props.data.active && props.data.showActive ? 'card-active' : ''}`} {...props.data.showActive ? { style: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } } : {}}>
                <div>
                    <img src={`/assets/images/logo/${props.data.icon}.svg`} style={{ height: 60 }} alt=""></img>
                </div>
                <div  {...props.data.detail ? {} : { style: { borderBottom: 'unset' } }} >
                    <p style={{ textTransform: 'uppercase', fontSize: 14 }}>
                        {`${props.data.title}`}
                    </p>
                </div>
                {props.data.detail &&
                    <div style={{ height: props.data.detailHeight ? props.data.detailHeight : 160 }}>
                        <p style={{ textAlign: props.data.textAlign ? props.data.textAlign : 'left' }}>
                            {`${props.data.detail}`}
                        </p>
                        {props.data.url &&
                            <a href={props.data.url.target} style={{ marginTop: 10 }} {...props.data.url.newWindow ? { target: '_blank' } : {}}>
                                {props.data.url.icon ?
                                    <img src={`/assets/images/logo/${props.data.url.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                    :
                                    <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                }
                                {props.data.url.text}
                            </a>
                        }
                        {props.data.btn &&
                            <div onClick={props.data.btn.action} style={{ marginTop: 10 }} className={`button ${props.data.btn.className ? props.data.btn.className : ''}`}>
                                {props.data.btn.icon ?
                                    <img src={`/assets/images/logo/${props.data.btn.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                    :
                                    <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                }
                                {props.data.btn.text}
                            </div>
                        }
                    </div>
                }
            </div>
            {props.data.showActive &&
                <div className={`animated zoomIn fast`} style={{ display: props.data.active ? '' : 'none', animationDuration: '300ms' }}>
                    <div style={{ height: 4, background: `${props.data.active ? '#0f72c9' : 'transparent'}`, marginTop: 5, borderRadius: 2, transition: '200ms', animationDelay: '100ms' }}></div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 3, visibility: props.data.active ? 'visible' : 'hidden' }}>
                        <div style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', width: 30, height: 30, borderRadius: '50%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="fa fa-arrow-down" style={{ fontSize: 14 }}></i>
                        </div>
                    </div>
                </div>

            }
        </div >
    )
}

export default FunctionCard;