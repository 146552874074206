import React from 'react'
import IPhoneMockup from '../iphone-mockup/iphone-mockup'
import intl from "react-intl-universal";

function Intro() {

    return (
        <section id="intro" className="section animated fadeIn faster" style={{ zIndex: 1000, backgroundImage: "url('/assets/images/business-sms.svg')", backgroundSize: "cover", backgroundPosition: "center", height: 450 }}>
            {/* <Particles style={{ position: 'absolute', left: 0, top: 0 }}
                params={{
                    "particles": {
                        "number": {
                            "value": 20,
                            "density": {
                                "enable": false
                            }
                        },
                        "size": {
                            "value": 6,
                            "random": true,
                            "anim": {
                                "speed": 0.4,
                                "size_min": 0.3
                            }
                        },
                        "line_linked": {
                            "enable": false
                        },
                        "move": {
                            "random": true,
                            "speed": 0.4,
                            "direction": "top",
                            "out_mode": "out"
                        }
                    },
                    "interactivity": {
                        "events": {
                            "onclick": {
                                "enable": true,
                                "mode": "repulse"
                            }
                        },
                        "modes": {

                            "repulse": {
                                "distance": 400,
                                "duration": 4
                            }
                        }
                    }
                }}
            ></Particles> */}

            <div className="container" >
                <div className="row">
                    <div className="col-md-8">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 270 }}>
                            <img src="/assets/images/logo/enterprisesms.svg" style={{ width: 350, marginBottom: 30 }} alt=''></img>
                            <h1 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px', textTransform: 'uppercase', margin: '0 10% 30px', fontSize: 16 }}>
                                {intl.get('sms.intro.detail')}
                            </h1>
                            <button className={`button blob-animate blue`} style={{ fontSize: 14 }} onClick={() => {
                                window.frames["tidioChatApi"].open();
                                window.frames["tidioChatApi"].messageFromOperator(intl.get('global.tidio-sms-msg'));
                            }}>
                                <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                {intl.get('global.start-free')}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 animated fadeIn faster crowdy-portal" style={{ animationDelay: '500ms', display: 'flex', justifyContent: 'center', height: 0 }}>
                        <IPhoneMockup
                            text={intl.get('sms.intro.mockup-text')}
                            date={new Date()}
                            size={'lg'}
                            shortCode="85414"
                            enableLinks={false}
                        />
                    </div>
                    {/* <div className="col-md-4 wow fadeIn crowdy-portal" style={{ animationDelay: '500ms' }}> */}
                    {/* <lottie-player
                            src={`/assets/lottie/29205-two-men-at-a-mobile-phone.json`} loop
                            background="transparent" speed="1" style={{ height: 300, display: "flex", justifyContent: "center" }} autoplay></lottie-player> */}

                    {/* <img src={`/assets/images/illustrations/undraw_personal_text_vkd8.svg`} style={{ maxWidth: '90%', maxHeight: '50%' }} alt="" /> */}
                    {/* </div> */}
                    {/* <div className="col-md-12">
                        <a href="#about">
                            <div className="mouse-icon" style={{ marginTop: -50 }}>
                                <div className="wheel"></div>
                            </div>
                        </a>

                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default Intro;