import React from "react";
import intl from "react-intl-universal";

function SMS2() {

    const getCard = (data) => {
        return (
            <div className="card"
                // style={{ background: 'transparent' }}
            >
                <div>
                    <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 48 }} alt=""></img>
                </div>
                <div>
                    <p>
                        {`${data.title}`}
                    </p>
                </div>
                <div style={{ height: 80 }}>
                    <p>
                        {`${data.detail}`}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div id="whysms">
            <section className="section alt-section animated fadeIn faster" >
                <div className="container fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/comment.svg`} style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} alt=""/>
                            <h2>{intl.get('sms.stats.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms', marginBottom: 20 }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        icon: 'successsms',
                                        title: '98%',
                                        detail: intl.get('sms.stats.opening')
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        icon: 'stopwatch',
                                        title: '90%',
                                        detail: intl.get('sms.stats.read')
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        icon: 'shortUrl',
                                        title: '18%',
                                        detail: intl.get('sms.stats.links')
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                {getCard(
                                    {
                                        icon: 'chatsend',
                                        title: '500M +',
                                        detail: intl.get('sms.stats.ammount')
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SMS2;