import React from 'react'
import Particles from 'react-particles-js';

function Intro() {
    let particleParameters = {
        "particles": {
            "number": {
                "value": 20,
                "density": {
                    "enable": false
                }
            },
            "size": {
                "value": 6,
                "random": true,
                "anim": {
                    "speed": 0.4,
                    "size_min": 0.3
                }
            },
            "line_linked": {
                "enable": false
            },
            "move": {
                "random": true,
                "speed": 0.4,
                "direction": "top",
                "out_mode": "out"
            }
        },
        "interactivity": {
            "events": {
                "onclick": {
                    "enable": true,
                    "mode": "repulse"
                }
            },
            "modes": {
                "repulse": {
                    "distance": 400,
                    "duration": 4
                }
            }
        }
    }

    return (
        <section id="intro" className="section" style={{ background: 'linear-gradient(135deg, #261675 0%, #00abff 140%)' }}>
            {/* <section id="intro" className="section" > */}
            <Particles style={{ position: 'absolute', left: 0, top: 0 }}
                params={particleParameters}
            ></Particles>

            <div className="container" id="particlejs">
                <div className="row">
                    <div className="col-md-8">
                        <div className="hello wow fadeIn faster">
                            <br />
                            <h3 style={{ textShadow: 'rgb(0 0 0 / 58%) 7px 1px 20px', textTransform: 'none', margin: '0 10%' }}>
                                Explore integraciones disponibles con otras plataformas, además nuestros ingenieros estan a su disposición para cualquier proceso de consultoría e integración.
                                </h3>
                        </div>
                    </div>
                    <div className="col-md-4 wow fadeIn crowdy-portal" style={{ animationDelay: '500ms' }}>
                        <img src={`/assets/images/illustrations/undraw_prototyping_process_rswj.svg`} style={{ maxWidth: '90%' }} alt="" />
                    </div>
                    <div className="col-md-12">
                        <a href="#about">
                            <div className="mouse-icon" style={{ marginTop: -50 }}>
                                <div className="wheel"></div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;