import React, { useEffect, useState, useRef } from "react";
import CountryBox from "./CountryBox";
import MultiValueItem from "./MultiValueItem";
import intl from "react-intl-universal";


const SMSCalculator = (props) => {

    const [selectedCountry, setSelectedCountry] = useState(undefined)
    const [selectedCurrency, setSelectedCurrency] = useState(0)
    const [globalPrice, setGlobalPrice] = useState(0);
    const [animateOut] = useState(false);
    const [smsPrice, setSmsPrice] = useState(0);
    const [smsCount, setSmsCount] = useState(0);
    const [volumeDiscount, setVolumeDiscount] = useState(false);
    const [dynamicKey, setDynamicKey] = useState(1)
    const [currencyItem, setCurrencyItem] = useState(undefined)
    const [smsPricingItems, setSmsPricingItems] = useState([
        {
            group: 1,
            label: 'SMS Marketing',
            shortLabel: 'SMS Marketing',
            icon: 'chatsend',
            value: 0,
            unitType: 1,
            unitTypeValue: 0,
            inputRef: useRef(),
            additionalDetail: '',
            includeInSMSCount: true,
            id: 0
        },
        {
            group: 1,
            label: 'SMS Marketing con URL única',
            shortLabel: 'SMS con URL única',
            icon: 'smsurl',
            value: 0,
            unitType: 1,
            unitTypeValue: 0,
            inputRef: useRef(),
            additionalDetail: 'Servicio URL única',
            includeInSMSCount: false,
            id: 2
        },
        {
            group: 1,
            label: 'SMS Marketing con URL Personalizada',
            shortLabel: 'SMS con URL personalizada',
            icon: 'smsurl',
            value: 0,
            unitType: 1,
            unitTypeValue: 0,
            inputRef: useRef(),
            additionalDetail: 'Servicio URL personalizada',
            includeInSMSCount: true,
            id: 3
        },
        {
            group: 2,
            label: 'SMS OTP y 2FA',
            shortLabel: 'SMS OTP 2FA',
            icon: 'smsotp',
            value: 0,
            unitType: 1,
            unitTypeValue: 0,
            inputRef: useRef(),
            additionalDetail: 'SMS + Servicio OTP y 2FA',
            includeInSMSCount: true,
            id: 1
        },
        {
            group: 2,
            label: 'SMS Notificación',
            shortLabel: 'SMS Notificacion',
            icon: 'smsotp',
            value: 0,
            unitType: 1,
            unitTypeValue: 0,
            inputRef: useRef(),
            additionalDetail: 'SMS + Servicio OTP y 2FA',
            includeInSMSCount: true,
            id: 1
        },
    ]);

    const smsVolumeDiscountLimit = 2000000;

    const [smsPricingbyCountry] = useState([
        {
            "alpha-2": 'CO', pricingList: [
                {
                    currency: 'COP',
                    locale: 'es-CO',
                    value: 6,
                    volumeDiscount: 0.5,
                    acccessFee: 300000,
                    pricingItems: [
                        {
                            id: 1,
                            additionalCost: 2
                        },
                        {
                            id: 2,
                            additionalCost: 1
                        },
                        {
                            id: 3,
                            additionalCost: 2
                        }]
                },
                {
                    currency: 'USD',
                    locale: 'en-US',
                    value: 0.0017,
                    volumeDiscount: 0.00014,
                    acccessFee: 72,
                    pricingItems: [
                        {
                            id: 1,
                            additionalCost: 0.000566
                        },
                        {
                            id: 2,
                            additionalCost: 0.00028322
                        },
                        {
                            id: 3,
                            additionalCost: 0.000566
                        }]
                }
            ],

        }
    ])

    const groupsDetail = {
        1: {
            label: "Servicios de SMS Marketing",
            icon: 'contacts'
        },
        2: {
            label: "Servicios de SMS Notificaciones, OTP y MFA",
            icon: 'typing'
        }
    }

    useEffect(() => {
        var observer = undefined;
        if (selectedCountry !== undefined) {

            observer = new IntersectionObserver((records, observer) => {
                let target = document.querySelector('#sticky-price-container');
                if (target) {
                    if (records[0].intersectionRatio === 0) {
                        document.querySelector('#sticky-price-container').parentNode.classList.add('header-price-sticky')
                    }
                    else if (records[0].intersectionRatio === 1) {
                        document.querySelector('#sticky-price-container').parentNode.classList.remove('header-price-sticky')
                    }
                }
            }, { threshold: [0] });

            let target = document.querySelector('#sticky-price-container');
            if (target) {
                observer.observe(document.querySelector('#sticky-price-container'))
            }
        }
        return (() => {
            if (observer)
                observer.disconnect()
        })

    }, [selectedCountry]);

    useEffect(() => {
        if (selectedCountry) {
            let currencyItem = smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList[selectedCurrency];
            let smsPrice = volumeDiscount ? currencyItem.value - currencyItem.volumeDiscount : currencyItem.value;
            setSmsPrice(smsPrice);
            setCurrencyItem(currencyItem)
        }
    }, [selectedCountry, smsPrice, volumeDiscount, selectedCurrency, smsPricingbyCountry])

    const smsFreeServiceList = [
        {
            anchor: 12,
            title: intl.get('calc.sms.free.validator.title'),
            icon: "clientsource",
            detail: intl.get('calc.sms.free.validator.detail'),
            detailHeight: 'auto'
        }
    ]

    const smsServiceList = [
        {
            anchor: 12,
            title: intl.get('calc.sms.services.shorturl.title'),
            icon: "shorturl",
            detail: intl.get('calc.sms.services.shorturl.detail'),
            detailHeight: 'auto',
            url: {
                target: '/shorturl',
                text: intl.get('global.service-more'),
                newWindow: true
            }
        },
        {
            anchor: 12,
            title: intl.get('calc.sms.services.2fa.title'),
            icon: "typing",
            detail: intl.get('calc.sms.services.2fa.detail'),
            detailHeight: 'auto',
            url: {
                target: '/otp',
                text: intl.get('global.service-more'),
                newWindow: true
            }
        },
        {
            anchor: 12,
            title: intl.get('calc.sms.services.cert.title'),
            icon: "cert",
            detail: intl.get('calc.sms.services.cert.detail'),
            detailHeight: 'auto'
        },
    ]

    const calculateGlobalPricing = () => {
        let smsCount = smsPricingItems.reduce((acumulator, current) => {
            return acumulator + (current.includeInSMSCount ? (current.value * current.unitTypeValue) : 0)
        }, 0);

        setSmsCount(smsCount)
        setVolumeDiscount(smsCount >= smsVolumeDiscountLimit ? true : false);

        // let currencyItem = smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList[selectedCurrency];

        let globalPrice = smsPricingItems.reduce((acumulator, current) => {
            // let itemAmmount = current.includeInSMSCount ? (current.value * current.unitTypeValue) : 0;
            let itemAmmount = current.value * current.unitTypeValue;
            let additionalCostItem = currencyItem.pricingItems.find(item => item.id === current.id);
            let itemPrice = 0;
            if (current.includeInSMSCount)
                itemPrice = itemAmmount * ((volumeDiscount ? currencyItem.value - currencyItem.volumeDiscount : currencyItem.value) + (additionalCostItem ? additionalCostItem.additionalCost : 0))
            else {
                itemPrice = itemAmmount
            }
            return acumulator + itemPrice;
        }, 0);

        setGlobalPrice(smsCount >= 1000000 ? globalPrice : globalPrice + currencyItem.acccessFee);

    }

    const renderGroupedItems = () => {
        let groups = smsPricingItems.reduce((grouped, item, index) => {
            const { group } = item;
            grouped[`${group}`] = grouped[`${group}`] ?? [];
            grouped[`${group}`].push({ ...item, index: index });
            return grouped;
        }, {});

        let groupedKeys = Object.keys(groups)
        return groupedKeys.map((groupItem, index) => {
            return (
                <MultiValueItem
                    key={`${groupItem}[${index}][${dynamicKey}]`}
                    data={groups[groupItem]}
                    dataName={groupItem}
                    smsPricingbyCountry={smsPricingbyCountry}
                    selectedCountry={selectedCountry}
                    selectedCurrency={selectedCurrency}
                    groupData={groupsDetail[`${groupItem}`]}
                    // value={data.value}
                    // unitType={data.unitType}
                    volumeDiscount={volumeDiscount}
                    onChange={(index, value, unitType, unitTypeValue) => {
                        smsPricingItems[index].value = value;
                        smsPricingItems[index].unitType = unitType;
                        smsPricingItems[index].unitTypeValue = unitTypeValue
                        calculateGlobalPricing();
                    }}
                ></MultiValueItem>
            )
        })
    }

    const getFormattedPrice = (value, currencyItem) => {
        let options = {
            style: 'currency',
            currency: currencyItem.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 5,
        };

        return Intl.NumberFormat(currencyItem.locale, options).format(value)
    }

    const getCard = (data, index) => {
        return (
            <div key={index} {...data.onClick ? { onClick: data.onClick } : {}} className={`col-md-${data.anchor}`} style={data.showPointer ? { marginBottom: 20, cursor: 'pointer' } : { marginBottom: 20, }}>
                <div className={`card ${data.showAnimation ? '' : 'card-nohover'} ${data.active && data.showActive ? 'card-active' : ''}`} {...data.showActive ? { style: { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } } : { style: { flexDirection: 'unset', borderRadius: 4 } }}>
                    <div style={{ width: 0 }}>
                    </div>
                    <div  {...data.detail ? { style: { borderBottom: 'unset', width: 140 } } : { style: { borderBottom: 'unset' } }} >
                        <img src={`/assets/images/logo/${data.icon}.svg`} style={{ height: 36 }} alt=""></img>
                        <p style={{ textTransform: 'uppercase', fontSize: 11 }}>
                            {`${data.title}`}
                        </p>
                    </div>
                    {data.detail &&
                        <div style={{ height: data.detailHeight ? data.detailHeight : 160 }}>
                            <p style={{ textAlign: 'left', padding: '5px 5px 5px 15px' }}>
                                {`${data.detail}`}
                            </p>
                            {data.url &&
                                <a href={data.url.target} style={{ marginTop: 10 }} {...data.url.newWindow ? { target: '_blank' } : {}}>
                                    {data.url.icon ?
                                        <img src={`/assets/images/logo/${data.url.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.url.text}
                                </a>
                            }
                            {data.btn &&
                                <div onClick={data.btn.action} style={{ marginTop: 10 }} className={`button ${data.btn.className ? data.btn.className : ''}`}>
                                    {data.btn.icon ?
                                        <img src={`/assets/images/logo/${data.btn.icon}.svg`} style={{ marginRight: 10, height: 20 }} alt=""></img>
                                        :
                                        <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    }
                                    {data.btn.text}
                                </div>
                            }
                        </div>
                    }
                </div>
                {data.showActive &&
                    <div className={`animated ${animateOut ? 'zoomOut faster' : 'zoomIn fast'}`} style={{ display: data.active ? '' : 'none', animationDuration: '300ms' }}>
                        <div style={{ height: 4, background: `${data.active ? '#0f72c9' : 'transparent'}`, marginTop: 5, borderRadius: 2, transition: '200ms', animationDelay: '100ms' }}></div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 3, visibility: data.active ? 'visible' : 'hidden' }}>
                            <div style={{ background: 'linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255))', width: 30, height: 30, borderRadius: '50%', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="fa fa-arrow-down" style={{ fontSize: 14 }}></i>
                            </div>
                        </div>
                    </div>

                }
            </div >
        )
    }

    return (
        <div style={{ padding: 20, background: '#4b8bd10d', transition: '200ms', borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h4 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/assets/images/logo/smscalculator.svg" style={{ height: 48, marginRight: 15 }} alt=""></img>
                {intl.get('calc.sms.title')}
            </h4>

            <CountryBox label={intl.get('calc.sms.country-title')}
                enabledFilter={smsPricingbyCountry}
                placeholder={intl.get('calc.sms.country-placeholder')}
                onSelect={(value) => {

                    let currencyItem = undefined;
                    if (value)
                        currencyItem = smsPricingbyCountry.find(item => item['alpha-2'] === value['alpha-2']).pricingList[0];

                    setSelectedCurrency(0); setSelectedCountry(value); setCurrencyItem(currencyItem)
                }}
            />

            {selectedCountry ?
                <div className="col-md-12 calculator-pricing-grid" style={{ position: 'relative' }}>
                    <div className="col-md-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="col-md-12" style={{ marginBottom: 20 }}>
                            <div className="col-md-12 calculator-pricing-note">
                                <h5>
                                    <img src="/assets/images/logo/pricing2.svg" alt=""></img>
                                    {intl.get('calc.sms.notes.pricing.title')}
                                </h5>
                                <h6>{intl.getHTML('calc.sms.notes.pricing.detail')}</h6>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 20 }}>
                            <div className="col-md-12 calculator-pricing-note">
                                <h5>
                                    <img src="/assets/images/logo/volume.svg" alt=""></img>
                                    {intl.get('calc.sms.notes.volume.title')}
                                </h5>
                                <h6>{intl.getHTML('calc.sms.notes.volume.detail')}</h6>
                            </div>
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 20 }}>
                            <div className="col-md-12 calculator-pricing-note">
                                <h5>
                                    <img src="/assets/images/logo/segment.svg" alt=""></img>
                                    {intl.get('calc.sms.notes.segment.title')}
                                </h5>
                                <h6>{intl.getHTML('calc.sms.notes.segment.detail')}</h6>
                            </div>
                        </div>

                        <h5 style={{ margin: '10px 0px 10px 25px', width: '100%' }}>
                            <img src="/assets/images/logo/crowdylogo.svg" alt=""></img>
                            {intl.get('calc.sms.free.title')}</h5>
                        <div style={{ margin: 0, padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} >
                            {smsFreeServiceList.map(getCard)}
                        </div>
                        <h5 style={{ margin: '30px 0px 10px 25px', width: '100%' }}>
                            <img src="/assets/images/logo/crowdylogo.svg" alt=""></img>
                            {intl.get('calc.sms.services.title')}</h5>
                        <div style={{ margin: 0, padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} >
                            {smsServiceList.map(getCard)}
                        </div>
                    </div>
                    <div key={1} className="col-md-12 animated fadeIn faster" style={{
                        background: '#4b8bd10d',
                        borderRadius: 4, padding: '20px 20px 0 20px', display: 'flex', flexDirection: 'column', alignItems: 'center'
                    }}>
                        <div style={{ height: 1, position: 'absolute', top: -50 }} id="sticky-price-container"></div>
                        <div className="col-md-12 calculator-pricind-detail" style={{ position: 'sticky', top: 70 }}>
                            <h4 style={{ marginBottom: 0 }}>
                                <img src={`/assets/images/logo/estimate.svg`} style={{ height: 36, marginRight: 7 }} alt=""></img>
                                {'Estimación de Servicios SMS'}</h4>
                            {(() => {
                                // let currencyItem = smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList[selectedCurrency];
                                return (
                                    <p style={{ marginBottom: 0 }}>{` ${currencyItem.currency} ${getFormattedPrice(globalPrice, currencyItem)}`}</p>
                                )
                            })()}

                            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'wrap' }}>
                                <div className="calculator-currency">
                                    <div style={{ background: 'transparent', border: '1px solid #3c89ea', display: 'flex' }}>
                                        <img src={`/assets/images/logo/pricing.svg`} style={{ height: 17, marginRight: 7 }} alt=""></img>
                                        <p style={{ marginBottom: 0 }}>
                                            {'Moneda'}
                                        </p>
                                    </div>
                                    {/* {smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList.map((item, index) => ( */}
                                    {smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList.map((item, index) => (
                                        <div key={index} onClick={() => setSelectedCurrency(index)} className={`${selectedCurrency === index ? 'active' : ''}`}>
                                            {item.currency}
                                        </div>
                                    ))}
                                </div>
                                <div className="calculator-currency" style={{ marginLeft: 15 }}>
                                    <div style={{ background: 'transparent', border: '1px solid #3c89ea', display: 'flex' }}>
                                        <img src={`/assets/images/logo/handshake.svg`} style={{ height: 17, marginRight: 7 }} alt=""></img>
                                        <p style={{ marginBottom: 0 }}>
                                            {'Fee de Acceso'}
                                        </p>
                                    </div>
                                    <div className={`active`}>{smsCount < 1000000 ? 'Si' : 'No'}</div>
                                </div>
                                {/* </div>
                            <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                                <div className="calculator-currency" style={{ marginBottom: 10 }}>
                                    <div style={{ background: 'transparent', border: '1px solid #3c89ea', display: 'flex' }}>
                                        <img src={`/assets/images/logo/pricing2.svg`} style={{ height: 17, marginRight: 7 }} alt=""></img>
                                        <p style={{ marginBottom: 0 }}>
                                            {'Costo Base SMS'}
                                        </p>
                                    </div>
                                    {(() => {
                                        // let currencyItem = smsPricingbyCountry.find(item => item['alpha-2'] === selectedCountry['alpha-2']).pricingList[selectedCurrency];
                                        return (
                                            <div className={`active`}>
                                                {`${Intl.NumberFormat(currencyItem.locale,
                                                    {
                                                        style: 'currency',
                                                        currency: currencyItem.currency,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 5,
                                                    }).format(smsPrice)}`}
                                            </div>
                                        )
                                    })()}
                                </div>
                                <div className="calculator-currency" style={{ marginBottom: 10, marginLeft: 15 }}>
                                    <div style={{ background: 'transparent', border: '1px solid #3c89ea', display: 'flex' }}>
                                        <img src={`/assets/images/logo/volume.svg`} style={{ height: 17, marginRight: 7 }} alt=""></img>
                                        <p style={{ marginBottom: 0 }}>
                                            {'Descuento x Vol'}
                                        </p>
                                    </div>
                                    <div className={`active`}>{volumeDiscount ? 'Si' : 'No'}</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>

                                <button className={`button`} onClick={() => {
                                    window.frames["tidioChatApi"].open();
                                    window.frames["tidioChatApi"].messageFromOperator("Estás interesado en probar nuestro servicio de Mensajeria SMS de forma gratuita!");
                                }}>
                                    <i className="fa fa-arrow-right" style={{ marginRight: 10 }}></i>
                                    {'Empieza Gratis'}
                                </button>
                                <button className={`button`} style={{ marginLeft: 10, border: 'solid 1px #3c89ea', fontWeight: 600, color: '#3c89ea', background: 'transparent' }}
                                    onClick={() => {
                                        var newPricing = [...smsPricingItems].map(item => {
                                            return {
                                                ...item,
                                                value: 0,
                                                unitType: 1,
                                                unitTypeValue: 1000
                                            }
                                        })
                                        setSmsPricingItems(newPricing)
                                        setDynamicKey(dynamicKey + 1)
                                    }}>
                                    <i className="fa fa-redo" style={{ marginRight: 10 }}></i>
                                    {'Limpiar'}
                                </button>
                            </div>
                        </div>
                        {/* {smsPricingItems.map(getPricingItem)} */}
                        {renderGroupedItems()}

                    </div>
                </div>
                :
                <>
                    <div key={2} className="col-md-6 animated fadeIn faster" style={{
                        // background: '#4b8bd10d',
                        borderRadius: 4, padding: 20, marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center'
                    }}>

                        <dotlottie-player
                            src={`/assets/lottie/loadingface.lottie`}
                            background="transparent" speed="0.8" loop style={{ width: 300, height: 300, marginTop: -70, display: "flex", justifyContent: "center", overflow: 'hidden' }} autoplay></dotlottie-player>

                        {/* <p
                            dangerouslySetInnerHTML={
                                { __html: EmojiText.getFlagEmojiByISOName('', 50, '🌎', 'google').innerHTML }
                            }
                            style={{ position: 'relative', paddingTop: 2, margin: '0 10px', fontFamily: 'SFRegular', fontSize: props.size ? props.size === 'md' || props.size === 'sm' ? 9 : 11 : 9, marginTop: 1, lineHeight: 1.35 }}>
                        </p> */}
                        <h4>
                            {intl.get('calc.sms.select-country')}
                        </h4>
                    </div>
                </>
            }
        </div>
    )
}

export default SMSCalculator;