import React from "react";

function Features() {
    return (
        <div>
            {/* <section id="about-header" className="section-header" >
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Nosotros</h2>
                                <p>Acerca de nuestros valores y motivaciones.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="whysms" className="section">
                <div className="container  wow fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2>¿Por qué Whatsapp?</h2>
                            <p>Evoluciona tus canales de comunicación.</p>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">

                        <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 420 }}>
                            <div className="col-md-12">
                                <div className="service" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="text">
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Adquisición de leads de clientes'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Reducción de costos de soporte con bots basados en Open AI.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Sugerencias/recomendaciones/opiniones de compras y productos.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Servicios de posventa.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Recibos de transacciones y concursos en línea'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Es altamente personalizable según las necesidades de cada negocio.'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 illustration" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:420 }}>
                            <lottie-player
                                src={`/assets/lottie/106596-work-chat.json`}
                                background="transparent" speed="1" style={{ with: 400, height:300 }} autoplay loop></lottie-player>
                            {/* <lottie-player
                                src={`/assets/lottie/28784-businessmen-at-the-table.json`} loop
                                background="transparent" speed="1" style={{ height: 400, display: "flex", justifyContent: "center" }} autoplay></lottie-player> */}
                            {/* <img src={`/assets/images/illustrations/undraw_product_iteration_kjok.svg`} style={{ maxWidth: '80%' }} alt="" /> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Features;