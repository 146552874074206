import React from "react";
import intl from 'react-intl-universal'
import FunctionCard from "../general/FunctionCard";

function Features() {

    const cardItems = [
        {
            anchor: 6,
            icon: 'automation',
            title: intl.get('home.features.cards.dash.title'),
            detail: intl.get('home.features.cards.dash.detail'),
            textAlign: 'center',
            detailHeight: 60,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'costcenter',
            title: intl.get('home.features.cards.costcenter.title'),
            detail: intl.get('home.features.cards.costcenter.detail'),
            textAlign: 'center',
            detailHeight: 60,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'whatsapp',
            title: intl.get('home.features.cards.whatsapp.title'),
            detail: intl.get('home.features.cards.whatsapp.detail'),
            textAlign: 'center',
            detailHeight: 90,
            showAnimation: true
        },
        {
            anchor: 6,
            icon: 'chatsend',
            title: intl.get('home.features.cards.campaign.title'),
            detail: intl.get('home.features.cards.campaign.detail'),
            textAlign: 'center',
            detailHeight: 90,
            showAnimation: true
        }
    ]

    return (
        <div>
            <section id="about" className="section alt-section">
                <div className="container fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-sm-8" style={{ textAlign: 'center', color: '#364153' }}>
                            <img src={`/assets/images/logo/color-splash.svg`} alt="Características Web" style={{ height: 30, marginLeft: 3, marginRight: 8, marginBottom: 6 }} />
                            <h2>{intl.get('home.features.title')}</h2>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="calculator-pricing-grid">
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', flexFlow: 'wrap' }}>
                            {cardItems.map((item, index) => (
                                <FunctionCard data={item} key={index} />
                            ))}
                        </div>
                        <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={`/assets/images/MacCrowdy2.webp`} style={{ maxWidth: '80%' }} alt="" />
                        </div>

                    </div>
                </div>
            </section >
        </div >
    );
}

export default Features;