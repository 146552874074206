import React, { useEffect } from "react";

function About() {

    useEffect(() => {
        window.$("#about-products").owlCarousel({
            items: 1,
            navigation: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            slideSpeed: 1000,
            paginationSpeed: 1000,
            pagination: true,
            autoPlay: 15000,
            singleItem: true
        });

        // window.$('#about-header').parallax({ src: `/assets/images/parallax2.jpg` })

        // window.addEventListener("resize", refreshParallax);
        // window.addEventListener("scroll", refreshParallax);
    });

    return (
        <div>
            {/* <section id="about-header" className="section-header" >
                <div className="color-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h2>Nosotros</h2>
                                <p>Acerca de nuestros valores y motivaciones.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section id="about" className="section">
                <div className="container  wow fadeIn faster" style={{ animationDelay: '200ms' }}>
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: 30, textAlign: 'center', color: '#364153' }}>
                            <h2>Características</h2>
                            <p>Como podemos ayudarle.</p>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn faster" style={{ animationDelay: '400ms' }}>
                    <div className="row">
                        <div className="col-md-6 illustration" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 420 }}>
                            <img src={`/assets/images/illustrations/undraw_add_to_cart.svg`} alt='' style={{ maxWidth: '80%' }} />
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', height: 420 }}>
                            <div className="col-md-12">
                                <div className="service" style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="text">
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Levantamiento de leads comerciales.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Segmentación de sus clientes.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Reportes de sus campañas en CSV.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Envíos SMS a sus clientes que fueron captados por las campañas QR.'}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                            <img src={`/assets/images/serviceslogos/check.svg`} alt='' style={{ height: 24, marginBottom: 3, marginRight: 5 }} />
                                            <p style={{ textAlign: 'left' }}>
                                                {' Organización en la vigencia de las campañas.'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;